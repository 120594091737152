import FosterService from '../services/fosterService'
import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'
import useUserRoles from './useUserRoles'

const useFosterOwner = (fosterId: string) => {
  const currentOrganization = useAppStore.use.currentOrganization()
  const currentUser = trpc.user.current.useQuery({
    organizationId: currentOrganization.id,
  }).data

  const { isUserAdmin } = useUserRoles()

  const { foster } = FosterService.useFoster(fosterId)

  if (!currentUser || !foster) {
    return false
  }

  if (currentUser.id === foster.user.id) {
    return true
  }

  if (isUserAdmin) {
    return true
  }

  return false
}

export default useFosterOwner
