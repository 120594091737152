import { useCallback, useState } from 'react'
import { Control, FormState, useFieldArray } from 'react-hook-form'
import { View } from 'react-native'
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Text,
  TouchableRipple,
} from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import { uuidv7 } from 'uuidv7'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'
import trpc from '../../../../utils/trpc'
import FormFieldDate from '../../../form/FormFieldDate'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldOrganizationVaccines from '../../../organization/cards/formFields/FormFieldOrganizationVaccines'
import { FosterForm } from '../../fosterFormSchema'
import FosterVaccinationItem from './FosterVaccinationItem'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
}

const FosterVaccinations = ({ control, formState }: Props) => {
  const { styles } = useStyles(stylesheet)

  const [expandedIndex, setExpandedIndex] = useState<number>(-1)

  const breakpoint = UnistylesRuntime.breakpoint

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster, isLoading } = FosterService.useFoster(fosterId)

  const currentOrganizationId = useAppStore.use.currentOrganization().id

  const processVaccinationsMutation =
    trpc.foster.processVaccinations.useMutation()

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'vaccinations',
    shouldUnregister: false,
    keyName: 'key',
  })

  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove]
  )

  const handleAppend = useCallback(() => {
    append({
      fosterId: fosterId,
      id: uuidv7(),
      vaccineId: '',
      givenBy: null,
      dueDate: null,
      name: null,
    })

    setExpandedIndex(fields.length)
  }, [append, fields.length, fosterId])

  return (
    <View style={styles.root}>
      {fields.map((field, index) => {
        const vaccination = foster?.vaccinations?.find((v) => v.id === field.id)

        return (
          <View
            key={`${field.key}-vaccinations`}
            style={styles.vaccinationItemContainer}
          >
            <View style={styles.statusIconContainer}>
              {vaccination?.givenDate ? (
                <Icon color={'green'} size={24} source="check" />
              ) : (
                <Icon size={24} source="needle" />
              )}
            </View>
            <View style={styles.vaccinationFormFieldsContainer}>
              {breakpoint === 'xs' && (
                <View>
                  <TouchableRipple
                    accessibilityRole="button"
                    borderless
                    onPress={() => {
                      setExpandedIndex(index === expandedIndex ? -1 : index)
                    }}
                    style={styles.pressableContainer}
                  >
                    <>
                      <View style={styles.mobileVaccinationItemContainer}>
                        <Text>
                          {vaccination?.name}{' '}
                          {vaccination?.givenDate ? 'given' : 'due'}{' '}
                          {vaccination?.givenDate
                            ? vaccination.givenDate.toLocaleDateString()
                            : vaccination?.dueDate?.toLocaleDateString()}
                        </Text>
                        <Text style={styles.clinicTitle}>
                          {vaccination?.givenBy || 'No clinic specified'}
                        </Text>
                      </View>
                    </>
                  </TouchableRipple>
                  <FosterVaccinationItem
                    control={control}
                    formState={formState}
                    index={index}
                    open={expandedIndex === index}
                    vaccination={vaccination}
                  />
                  {expandedIndex === index && (
                    <TouchableRipple
                      accessibilityRole="button"
                      borderless
                      onPress={() => setExpandedIndex(-1)}
                      style={styles.vaccinationCollapseContainer}
                    >
                      <>
                        <Divider style={styles.flexContainer} />
                        <Icon size={32} source={'chevron-up'} />
                        <Divider style={styles.flexContainer} />
                      </>
                    </TouchableRipple>
                  )}
                </View>
              )}
              {breakpoint !== 'xs' && (
                <>
                  <FormFieldOrganizationVaccines
                    control={control}
                    fieldName={`vaccinations.${index}.vaccineId`}
                    formState={formState}
                    label="Delay From Vaccine"
                    style={styles.formField}
                  />
                  {!vaccination?.givenDate && (
                    <FormFieldDate
                      control={control}
                      errors={formState.errors}
                      fieldName={`vaccinations.${index}.dueDate`}
                      label="Due On"
                      required={false}
                      style={styles.formField}
                    />
                  )}
                  <FormFieldDate
                    control={control}
                    errors={formState.errors}
                    fieldName={`vaccinations.${index}.givenDate`}
                    label="Given On"
                    required={false}
                    style={styles.formField}
                  />
                  <FormFieldText
                    control={control}
                    errors={formState.errors}
                    fieldName={`vaccinations.${index}.givenBy`}
                    label="Clinic"
                    required={true}
                    style={styles.formField}
                  />
                </>
              )}
            </View>
            <View style={styles.deleteIconContainer}>
              <IconButton
                icon={'trash-can'}
                onPress={() => handleRemove(index)}
              />
            </View>
          </View>
        )
      })}
      <View style={styles.buttonRow}>
        <Button onPress={handleAppend} style={styles.formField}>
          Add Vaccination
        </Button>
        <Button
          loading={processVaccinationsMutation.isLoading || isLoading}
          onPress={() => {
            processVaccinationsMutation.mutate(
              {
                id: fosterId,
                organizationId: currentOrganizationId || '',
              },
              {
                onSuccess: async () => {
                  await refreshFoster()
                },
              }
            )
          }}
          style={styles.formField}
        >
          {!fields.length ? 'Generate Schedule' : 'Update Schedule'}
        </Button>
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    buttonRow: {
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      gap: theme.tokens.spacing[2],
    },
    clinicTitle: {
      color: theme.colors.subtitle,
    },
    deleteIconContainer: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    flexContainer: {
      flex: 1,
    },
    formField: {
      flex: 0.3,
    },
    mobileVaccinationItemContainer: {
      flex: 1,
      justifyContent: 'center',
      marginLeft: theme.tokens.spacing[7],
    },
    pressableContainer: {
      borderRadius: theme.tokens.containerBorderRadius,
      flex: 1,
      padding: theme.tokens.spacing[3],
    },
    root: {
      flex: 1,
    },
    statusIconContainer: {
      left: 0,
      paddingLeft: 14,
      paddingVertical: 14,
      position: 'absolute',
      top: 0,
    },
    vaccinationCollapseContainer: {
      alignItems: 'center',
      borderRadius: theme.tokens.containerBorderRadius,
      flexDirection: 'row',
      justifyContent: 'center',
      padding: theme.tokens.spacing[2],
    },
    vaccinationFormFieldsContainer: {
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      flex: 1,
      gap: 4,
    },
    vaccinationItemContainer: {
      flexDirection: 'row',
      flex: 1,
      gap: 4,
    },
  }
})

export default FosterVaccinations
