import * as DocumentPicker from 'expo-document-picker'
import { DragDropContentView } from 'expo-drag-drop-content-view'
import { Alert, Platform, View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import useScanDocument from '../../../hooks/useScanDocument'
import FosterService from '../../../services/fosterService'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'
import ScanDocumentAction from '../actions/ScanDocumentAction'
import DocumentsListItem from '../documents/DocumentListItem'

const FosterDocumentsCard = () => {
  const { styles } = useStyles(stylesheet)

  const currentOrganizationId = useAppStore.use.currentOrganization().id || ''

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const documentDeleteMutation = trpc.foster.documents.delete.useMutation()

  const hasDocuments = !!foster?.documents?.length

  const { scanDocuments, scanResults, setScanResults } = useScanDocument()

  const selectDocuments = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: ['application/pdf', 'image/*'],
    })

    if (!result.assets?.length) {
      return
    }

    if (
      result.assets?.filter((asset) => asset.mimeType?.startsWith('image'))
        .length
    ) {
      Alert.alert(
        'Images Detected',
        'We recommend only uploading PDF documents, and using the Scan Documents feature to capture paper documents.',
        [
          {
            text: 'Upload Anyway',
            onPress: async () => {
              if (result.assets?.length) {
                const uris = result.assets.map((asset) => asset.uri)

                setScanResults(uris)
              }
            },
          },
          {
            text: 'Scan Documents',
            style: 'cancel',
            onPress: scanDocuments,
          },
        ]
      )
    } else {
      setScanResults(result.assets.map((asset) => asset.uri))
    }
  }

  return (
    <DragDropContentView
      onDrop={async (files) => {
        const uris = files.assets
          .map((file) => file.uri)
          .filter(Boolean) as string[]

        setScanResults(uris)
      }}
    >
      <Card title="Documents">
        {!hasDocuments && <Text>No documents uploaded.</Text>}
        {hasDocuments && UnistylesRuntime.breakpoint !== 'xs' && (
          <View style={styles.headerContainer}>
            <Text style={[styles.nameColumnFlex, styles.headerColumn]}>
              Name
            </Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>Type</Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>
              Uploaded On
            </Text>
          </View>
        )}
        {hasDocuments &&
          foster?.documents.map((document) => {
            return (
              <>
                {
                  <DocumentsListItem
                    documentId={document.id}
                    key={document.id}
                    onDelete={async () => {
                      await documentDeleteMutation.mutateAsync({
                        id: document.id,
                      })

                      await refreshFoster()
                    }}
                  />
                }
              </>
            )
          })}
        <View style={styles.buttonRow}>
          {Platform.OS !== 'web' && (
            <Button icon={'scanner'} mode="contained" onPress={scanDocuments}>
              Scan Documents
            </Button>
          )}
          <Button icon={'upload'} mode="outlined" onPress={selectDocuments}>
            Upload Documents
          </Button>
        </View>
      </Card>
      {scanResults?.length && (
        <ScanDocumentAction
          currentOrganizationId={currentOrganizationId}
          fosterId={fosterId}
          onDismiss={() => setScanResults(null)}
          scanUris={scanResults}
        />
      )}
    </DragDropContentView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    buttonRow: {
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      gap: theme.tokens.spacing[3],
      marginTop: theme.tokens.spacing[3],
    },
    columnFlex: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },
    headerColumn: {
      color: theme.colors.primary,
      fontWeight: 'bold',
    },
    headerContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 8,
    },
    nameColumnFlex: {
      flex: {
        xs: 1,
        sm: 0.5,
      },
    },
  }
})

export default FosterDocumentsCard
