import { StateCreator } from 'zustand'

type Filter = {
  [field: string]: {
    operator: string
    value: string | boolean | Date | null
    type: 'string' | 'boolean' | 'date' | 'enum'
  }
}

const defaultFilters = {
  isActive: false,
  isDirty: false,
  filters: null as Filter | null,
}

type AdvancedFilters = typeof defaultFilters

export interface AdvancedFilterSlice {
  advancedFilters: AdvancedFilters
  setAdvancedFilters: (filters: AdvancedFilters) => void
  resetAdvancedFilters: () => void
}

export const createAdvancedFilterSlice: StateCreator<AdvancedFilterSlice> = (
  set
) => ({
  advancedFilters: defaultFilters,
  setAdvancedFilters: ({ isActive, filters }: AdvancedFilters) => {
    set({
      advancedFilters: {
        isActive,
        isDirty: true,
        filters,
      },
    })
  },
  resetAdvancedFilters: () => {
    set((state) => ({
      advancedFilters: {
        ...defaultFilters,
        isActive: state.advancedFilters.isActive,
      },
    }))
  },
})
