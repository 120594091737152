import { StyleProp, ViewStyle } from 'react-native'
import { Avatar } from 'react-native-paper'

const InitialsAvatar = ({
  string,
  size,
  style,
}: {
  string: string
  size: number
  style: StyleProp<ViewStyle>
}) => {
  return (
    <Avatar.Text
      label={string
        .split(' ')
        .map((part) => part[0])
        .join('')
        .slice(0, 2)
        .toUpperCase()}
      size={size}
      style={[style, { backgroundColor: stringToColor(string) }]}
    />
  )
}

export default InitialsAvatar

// Generate a custom background color based on a string seed value.
// This keeps the color consistent for the same string while allowing for a random color distribution across a given set of avatars.
const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Step 2: Color Generation
  let color = '#'
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}
