import { useState } from 'react'
import DocumentScanner, {
  ResponseType,
  ScanDocumentResponseStatus,
} from 'react-native-document-scanner-plugin'
import Snackbar from '../components/snackbar/Snackbar'

export default function useScanDocument() {
  const [scanResults, setScanResults] = useState<string[] | null>(null)

  const scanDocuments = async () => {
    const result = await DocumentScanner.scanDocument({
      croppedImageQuality: 100,
      responseType: ResponseType.ImageFilePath,
    })

    if (result.status === ScanDocumentResponseStatus.Success) {
      if (!result.scannedImages) {
        Snackbar.error(
          'There was an error scanning the documents. Please try again.'
        )
        return
      }

      setScanResults(result.scannedImages)
    }
  }

  return {
    scanDocuments,
    scanResults,
    setScanResults,
  }
}
