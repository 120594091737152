import FontAwesome from '@expo/vector-icons/FontAwesome'
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import { Tabs, useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { UnistylesRuntime, useStyles } from 'react-native-unistyles'

const RootTabLayout = () => {
  const { theme } = useStyles()

  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  })

  const screenOptions: BottomTabNavigationOptions = {
    tabBarActiveTintColor: theme.colors.primary,
    animation: 'shift',
    headerShown: false,
    tabBarPosition: 'bottom',
    tabBarStyle: {
      backgroundColor: theme.colors.background,
      borderTopColor: theme.colors.cardBorder,
    },
  }

  if (['lg', 'xl'].includes(UnistylesRuntime.breakpoint)) {
    screenOptions.tabBarPosition = 'left'
    screenOptions.tabBarVariant = 'material'
    screenOptions.tabBarStyle = {
      ...(typeof screenOptions.tabBarStyle === 'object'
        ? screenOptions.tabBarStyle
        : {}),
      minWidth: 70,
    }

    screenOptions.tabBarActiveBackgroundColor = theme.colors.primaryContainer
    screenOptions.tabBarActiveTintColor = theme.colors.onPrimaryContainer
  }

  return (
    <Tabs
      backBehavior="history"
      initialRouteName="(fosters)"
      screenOptions={screenOptions}
    >
      <Tabs.Screen
        name="(fosters)"
        options={{
          title: 'Fosters',
          tabBarIcon: ({ color }) => (
            <FontAwesome color={color} name="home" size={28} />
          ),
        }}
      />
      <Tabs.Screen
        name="(dashboard)/index"
        options={{
          title: 'Dashboard',
          tabBarIcon: ({ color }) => (
            <FontAwesome color={color} name="dashboard" size={28} />
          ),
        }}
      />
      <Tabs.Screen name="index" redirect />
    </Tabs>
  )
}

export default RootTabLayout
