import React, { useEffect, useState } from 'react'
import { LayoutChangeEvent, View } from 'react-native'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

interface Props {
  children: React.ReactNode
  isLoading?: boolean
}

const Skeleton = ({ children, isLoading }: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const opacity = useSharedValue(1)

  const handleLayout = (event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout

    if (dimensions.width === width && dimensions.height === height) {
      return
    }

    setDimensions({ width, height })
  }

  useEffect(() => {
    if (isLoading) {
      opacity.value = withRepeat(
        withTiming(0.5, {
          duration: 750,
          easing: Easing.inOut(Easing.ease),
        }),
        -1,
        true
      )
    } else {
      opacity.value = 1
    }
  }, [isLoading, opacity])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    }
  })

  return (
    <View onLayout={handleLayout} style={styles.container}>
      {children}

      {isLoading && (
        <>
          <View
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              backgroundColor: theme.colors.surface,
              position: 'absolute',
              top: 0,
              left: 0,
              width: dimensions.width || '100%',
              height: dimensions.height || '100%',
              overflow: 'hidden',
              zIndex: 1000,
            }}
          />
          <Animated.View
            style={[
              styles.skeleton,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                position: 'absolute',
                top: 0,
                left: 0,
                width: dimensions.width || '100%',
                height: dimensions.height || '100%',
                overflow: 'hidden',
                zIndex: 1001,
              },
              animatedStyle,
            ]}
          />
        </>
      )}
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    container: {
      minHeight: 32,
    },
    skeleton: {
      backgroundColor: theme.colors.backdrop,
      borderRadius: theme.tokens.containerBorderRadius,
    },
  }
})

export default Skeleton
