import { Modal, Portal } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import DocumentEdit from './DocumentEdit'

interface Props {
  documentId: string
  open: boolean
  onClose: () => void
}

const DocumentEditModal = ({ documentId, open, onClose }: Props) => {
  const { styles } = useStyles(stylesheet)

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.root}
        onDismiss={onClose}
        visible={open}
      >
        <DocumentEdit documentId={documentId} onSubmit={onClose} />
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      alignSelf: 'center',
      backgroundColor: theme.colors.background,
      borderRadius: theme.tokens.containerBorderRadius,
      height: '90%',
      padding: theme.tokens.spacing[4],
      width: '90%',
    },
  }
})

export default DocumentEditModal
