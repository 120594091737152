import { useState } from 'react'
import { ScrollView } from 'react-native'
import { Searchbar } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import SearchResults from '../search/SearchResults'

const DesktopSearchBar = () => {
  const { styles } = useStyles(stylesheet)

  const [searchInput, setSearchQuery] = useState('')

  return (
    <>
      <Searchbar
        onChangeText={(text) => {
          setSearchQuery(text)
        }}
        placeholder="Search"
        style={{ width: 500 }}
        value={searchInput}
      />

      {searchInput && (
        <ScrollView style={styles.results}>
          <SearchResults searchInput={searchInput} />
        </ScrollView>
      )}
    </>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    results: {
      backgroundColor: theme.colors.background,
      borderRadius: theme.tokens.containerBorderRadius,
      maxHeight: 400,
      padding: theme.tokens.spacing[4],
      position: 'absolute',
      top: 70,
      width: 450,
    },
  }
})

export default DesktopSearchBar
