import {
  BottomSheetTextInput,
  useBottomSheetInternal,
} from '@gorhom/bottom-sheet'
import { BottomSheetInternalContextType } from '@gorhom/bottom-sheet/lib/typescript/contexts/internal'
import { useEffect, useState } from 'react'
import {
  TextInput as ActualTextInput,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  Text,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native'
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

const BLUR_ANIMATION_DURATION = 180
const FOCUS_ANIMATION_DURATION = 150

const TextInputLabel = Animated.createAnimatedComponent(Text)

const TextInput = (
  props: TextInputProps & {
    label?: string
    contentStyle?: StyleProp<ViewStyle>
    outlineStyle?: StyleProp<ViewStyle>
  }
) => {
  const { styles, theme } = useStyles(stylesheet)

  const labelPositionY = useSharedValue(0)
  const labelPositionX = useSharedValue(0)

  const [value, onTextChanged] = useState('')

  const [focused, setFocused] = useState(false)

  useEffect(() => {
    onTextChanged(props.value || '')
  }, [props.value])

  const focusedAnimation = () => {
    labelPositionY.value = withTiming(-25, {
      duration: FOCUS_ANIMATION_DURATION,
    })
    labelPositionX.value = withTiming(-5, {
      duration: FOCUS_ANIMATION_DURATION,
    })
  }

  const onFocus = () => {
    setFocused(true)
    focusedAnimation()
  }

  if (value.length > 0) {
    focusedAnimation()
  }

  const onBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocused(false)

    props.onBlur?.(e)

    if (value.length > 0) {
      return
    }

    labelPositionY.value = withTiming(0, { duration: BLUR_ANIMATION_DURATION })
    labelPositionX.value = withTiming(0, { duration: BLUR_ANIMATION_DURATION })
  }

  const animatedLabelStyle = useAnimatedStyle(() => {
    return {
      left: 5,
      zIndex: 1,
      backgroundColor: theme.colors.onSecondary,
      transform: [
        {
          translateY: labelPositionY.value,
        },
        {
          translateX: labelPositionX.value,
        },
        {
          scale: interpolate(
            labelPositionY.value,
            [-20, 0],
            [0.8, 1],
            Extrapolate.CLAMP
          ),
        },
      ],
    }
  })

  const bottomSheet: BottomSheetInternalContextType | null =
    useBottomSheetInternal(true)

  const FinalTextInput = bottomSheet ? BottomSheetTextInput : ActualTextInput

  return (
    <View style={[styles.inputContainer, props.contentStyle]}>
      <Animated.View
        pointerEvents="none"
        style={[styles.labelContainer, animatedLabelStyle]}
      >
        <TextInputLabel
          style={[
            styles.labelText,
            focused
              ? { color: theme.colors.primary }
              : { color: theme.colors.onSurfaceVariant },
          ]}
        >
          {props.label}
        </TextInputLabel>
      </Animated.View>
      <View
        style={[
          props.outlineStyle,
          { borderRadius: 4 },
          focused
            ? {
                borderColor: theme.colors.primary,
                borderWidth: 2,
                margin: -1,
              }
            : { borderColor: theme.colors.outline, borderWidth: 1 },
        ]}
      >
        <FinalTextInput
          {...props}
          onBlur={onBlur}
          onFocus={onFocus}
          style={[
            props.multiline && { paddingTop: 10 },
            props.style,
            styles.textInput,
            {
              outline: 'none',
            },
          ]}
        />
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    inputContainer: {
      paddingTop: 5, // Make room for the label to move up
    },
    labelContainer: {
      position: 'absolute',
      top: 21,
    },
    labelText: {
      backgroundColor: theme.colors.surface,
      color: theme.colors.onSurfaceVariant,
      fontSize: 16,
      paddingHorizontal: 5,
    },
    textInput: {
      borderRadius: theme.tokens.containerBorderRadius,
      color: theme.colors.onSurface,
      flex: Platform.OS === 'web' ? 1 : 0,
      fontSize: 16,
      justifyContent: 'center',
      minHeight: 50,
      paddingLeft: 10,
    },
  }
})

export default TextInput
