import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import {
  useGateValue,
  useStatsigClient,
  useStatsigUser,
} from '@statsig/expo-bindings'
import * as QuickActions from 'expo-quick-actions'
import { RouterAction, useQuickActionRouting } from 'expo-quick-actions/router'
import { Redirect, Stack } from 'expo-router'
import * as SplashScreen from 'expo-splash-screen'
import { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import { useStyles } from 'react-native-unistyles'
import { Feature } from '../../../constants'
import Header from '../../components/navigation/Header'
import useCurrentOrganization from '../../hooks/useCurrentOrganization'
import { useAppStore } from '../../store/useAppStore'
import * as datadog from '../../utils/datadog/datadog'
import trpc from '../../utils/trpc'

SplashScreen.preventAutoHideAsync()

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <Header {...props} />
}

const InnerAppLayout = () => {
  // Enable linking to the `href` param when a quick action is used.
  useQuickActionRouting()

  const { theme } = useStyles()

  const auth = useAppStore.use.auth()
  const { user: statsigUser, updateUserSync } = useStatsigUser()
  const hasDatadogRUM = useGateValue(Feature.DatadogRUM)

  const { currentOrganization } = useCurrentOrganization()
  const currentUserQuery = trpc.user.current.useQuery({
    organizationId: currentOrganization.id,
  })

  const { client: statsigClient } = useStatsigClient()

  useMemo(async () => {
    if (hasDatadogRUM) {
      await datadog.initialize()
    } else {
      datadog.stop()
    }

    statsigClient.on('gate_evaluation', (event) => {
      datadog.evaluateFeature(event.gate.name, event.gate.value)
    })
  }, [hasDatadogRUM, statsigClient])

  useEffect(() => {
    // Now you can configure your quick actions to link places (including externally):
    QuickActions.setItems<RouterAction>([
      {
        title: 'Search',
        icon: Platform.OS === 'ios' ? 'search' : 'shortcut_search',
        id: '0',
        params: { href: '/fosters/search' },
      },
    ])
  }, [])

  useEffect(() => {
    const currentUser = currentUserQuery.data
    if (!currentUser) {
      return
    }

    updateUserSync({
      ...statsigUser,
      custom: {
        ...statsigUser.custom,
        organizationId: currentOrganization.id,
      },
      userID: currentUser.id,
      email: currentUser.email,
    })

    datadog.setUser({
      id: currentUser.id,
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    })

    // disable the lint rule here specifically because statsigUser and updateUserSync change in this hook,
    // and we want to avoid a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrganization,
    currentUserQuery.data?.id,
    currentUserQuery.data?.email,
  ])

  if (auth.isLoggedIn === undefined) {
    return null
  }

  if (auth.isLoggedIn === false) {
    return <Redirect href="/login" />
  }

  return (
    <Stack
      screenOptions={{
        headerShown: true,
        header: HeaderComponent,
        contentStyle: {
          backgroundColor: theme.colors.background,
        },
      }}
    >
      <Stack.Screen name="(tabs)" />
    </Stack>
  )
}

export default InnerAppLayout
