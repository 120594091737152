import { StateCreator } from 'zustand'

export interface OrganizationSlice {
  currentOrganization: {
    id?: string
    logo: string
    name: string
  }
  setCurrentOrganization: (organization: {
    id?: string
    logo: string
    name: string
  }) => void
}

export const createOrganizationSlice: StateCreator<OrganizationSlice> = (
  set
) => ({
  currentOrganization: {
    id: undefined,
    logo: '',
    name: '',
  },
  setCurrentOrganization: ({ id, logo, name }) => {
    set({ currentOrganization: { id, logo, name } })
  },
})
