import FosterHealthCard from '../../../../../../components/fosters/cards/FosterHealthCard'
import { useFosterFormContext } from '../../../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../../../components/fosters/FosterTab'

const FosterMedicalTab = () => {
  const { control, formState } = useFosterFormContext()

  return (
    <FosterTab viewId="foster-health-tab">
      <FosterHealthCard control={control} formState={formState} />
    </FosterTab>
  )
}

export default FosterMedicalTab
