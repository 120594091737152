import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetFooter,
  BottomSheetFooterProps,
  BottomSheetModal,
  BottomSheetScrollView,
} from '@gorhom/bottom-sheet'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { Image, View } from 'react-native'
import { Button, Divider, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import { ApiRoot, DocumentSelectorTypes } from '../../../../constants'
import FosterService from '../../../services/fosterService'
import prepareFileUpload from '../../../utils/prepareFileUpload'
import FormFieldSelect from '../../form/FormFieldSelect'
import FormFieldText from '../../form/FormFieldText'
import Snackbar from '../../snackbar/Snackbar'

enum DocumentType {
  AdoptionContract = 'AdoptionContract',
  MedicalRecord = 'MedicalRecord',
  IntakeDocument = 'IntakeDocument',
  OwnerSurrenderForm = 'OwnerSurrenderForm',
  TrialRunAgreement = 'TrialRunAgreement',
}

interface Props {
  currentOrganizationId: string
  fosterId: string
  onDismiss: () => void
  scanUris: string[]
}

type FosterDocumentResponse = {
  fileName: string
  fosterName: string
  date: string
  entity: string
  documentType: string
  summary: string
  uri: string
}

const formSchema = z.object({
  scans: z.array(
    z.object({
      documentType: z.nativeEnum(DocumentType),
      documentName: z.string(),
    })
  ),
})

type FormData = z.infer<typeof formSchema>

const ScanDocumentAction = ({
  currentOrganizationId,
  fosterId,
  onDismiss,
  scanUris,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const [scans, setScans] = useState<(FosterDocumentResponse | undefined)[]>([])
  const bottomSheetRef = useRef<BottomSheetModal>(null)

  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const handleSheetChanges = useCallback(
    (index: number) => {
      if (index === -1) {
        onDismiss()
      }
    },
    [onDismiss]
  )

  const documentForm = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // @ts-expect-error - we know this will cause a validation error, which means the user will be forced to select something
      scans: scanUris.map(() => ({
        documentType: '',
        documentName: '',
      })),
    },
  })

  const { fields, append, replace } = useFieldArray({
    control: documentForm.control,
    name: 'scans',
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = documentForm

  const identify = useCallback(
    async (uris: string[]) => {
      setScans([])

      await Promise.all(
        uris.map(async (uri, index) => {
          const response = await fetch(
            `${ApiRoot()}/${currentOrganizationId}/${fosterId}/identify`,
            {
              method: 'POST',
              body: await prepareFileUpload({
                uri,
                fileName: 'scan.jpg',
                mimeType: 'image/jpeg',
              }),
            }
          )

          const document = (await response.json()) as FosterDocumentResponse
          document.uri = uri

          setScans((prevScans) => {
            const newScans = [...prevScans]
            newScans[index] = document
            return newScans
          })
        })
      )
    },
    [currentOrganizationId, fosterId]
  )

  const onSubmit = useCallback(
    async (data: FormData) => {
      await Promise.all(
        data.scans.map(async (scan, index) => {
          const response = await fetch(
            `${ApiRoot()}/${currentOrganizationId}/${fosterId}/document`,
            {
              method: 'POST',
              body: await prepareFileUpload({
                uri: scanUris[index],
                fields: {
                  documentType: scan.documentType,
                },
                fileName: scan.documentName,
                mimeType: 'image/jpeg',
              }),
            }
          )

          if (!response.ok) {
            Snackbar.error('Error saving document')
          }

          await refreshFoster()
        })
      )

      bottomSheetRef.current?.close()
    },
    [currentOrganizationId, fosterId, scanUris, refreshFoster, bottomSheetRef]
  )

  const renderBackdrop = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        disappearsOnIndex={-1}
        pressBehavior={'collapse'}
      />
    ),
    []
  )

  const renderFooter = useCallback(
    (props: BottomSheetFooterProps) => (
      <BottomSheetFooter {...props} bottomInset={0}>
        <View style={styles.bottomSheetFooter}>
          <Button
            disabled={documentForm.formState.isSubmitting}
            loading={documentForm.formState.isSubmitting}
            mode="contained"
            onPress={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
          >
            Upload
          </Button>
        </View>
      </BottomSheetFooter>
    ),
    [
      documentForm.formState.isSubmitting,
      styles.bottomSheetFooter,
      handleSubmit,
      onSubmit,
    ]
  )

  useEffect(() => {
    const subscription = documentForm.watch((values) => {
      for (let i = 0; i <= (values?.scans?.length || 0); i++) {
        const scan = values.scans?.[i]

        if (!scan) {
          continue
        }

        if (
          foster?.documents.some(
            (document) => document.name === scan.documentName
          )
        ) {
          documentForm.setError(`scans.${i}.documentName`, {
            message: 'A document with this name already exists.',
          })
        } else {
          documentForm.clearErrors(`scans.${i}.documentName`)
          documentForm.trigger(`scans.${i}.documentName`)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [documentForm, foster?.documents])

  useEffect(() => {
    console.log('scans', JSON.stringify(scans, null, 2))

    replace(
      scans.map((scan) => ({
        createdAt: scan?.date ? new Date(scan.date) : null,
        documentType: (scan?.documentType || '') as DocumentType,
        documentName: scan?.fileName || '',
      }))
    )
  }, [replace, scans])

  useEffect(() => {
    if (scanUris.length) {
      bottomSheetRef.current?.present()
    }

    scanUris.forEach((uri, index) => {
      if (!fields[index]) {
        // @ts-expect-error - we know this will cause a validation error, which means the user will be forced to select something
        append({ createdAt: null, documentType: '', documentName: '' })
      }
    })
  }, [scanUris, append, fields])

  useEffect(() => {
    identify(scanUris)
  }, [identify, scanUris])

  return (
    <BottomSheetModal
      backdropComponent={renderBackdrop}
      enableHandlePanningGesture={true}
      enablePanDownToClose={true}
      footerComponent={renderFooter}
      handleIndicatorStyle={styles.handleIndicator}
      handleStyle={styles.handle}
      keyboardBlurBehavior="restore"
      onChange={handleSheetChanges}
      ref={bottomSheetRef}
    >
      <BottomSheetScrollView
        contentContainerStyle={styles.bottomSheetContentContainer}
        nativeID="scan-import-bottom-sheet"
        style={styles.bottomSheet}
      >
        <Text style={styles.title} variant="headlineMedium">
          Import Scans
        </Text>

        <FormProvider {...documentForm}>
          {!scans?.length && (
            <Text style={{ marginBottom: 24 }}>
              Please wait as we try to identify the documents in the scans.
            </Text>
          )}
          {fields.map((field, index) => (
            <View key={field.id}>
              <View style={styles.documentRow}>
                <Image
                  accessibilityIgnoresInvertColors={true}
                  key={scanUris[index]}
                  source={{ uri: scanUris[index] }}
                  style={styles.documentPreview}
                />
                <View style={styles.formContainer}>
                  <FormFieldSelect
                    control={control}
                    data={DocumentSelectorTypes}
                    errors={errors.scans?.[index] as FieldErrors}
                    fieldName={`scans.${index}.documentType`}
                    isLoading={!scans[index]}
                    label="Document Type"
                    required
                  />

                  <FormFieldText
                    control={control}
                    errors={errors.scans?.[index] as FieldErrors}
                    fieldName={`scans.${index}.documentName`}
                    isLoading={!scans[index]}
                    label="Document Name"
                    required
                  />
                </View>
              </View>
              <Divider style={styles.divider} />
            </View>
          ))}
        </FormProvider>
      </BottomSheetScrollView>
    </BottomSheetModal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    bottomSheet: {
      backgroundColor: theme.colors.background,
      padding: theme.tokens.spacing[4],
    },
    bottomSheetContentContainer: {
      justifyContent: 'space-between',
    },
    bottomSheetFooter: {
      backgroundColor: theme.colors.background,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.tokens.spacing[4],
    },
    divider: {
      marginBottom: theme.tokens.spacing[5],
    },
    documentPreview: {
      alignContent: 'center',
      flex: 1,
      height: {
        xs: 300,
        sm: undefined,
      },
      justifyContent: 'center',
      marginBottom: {
        xs: theme.tokens.spacing[4],
        sm: undefined,
      },
      objectFit: 'contain',
      width: {
        xs: undefined,
        sm: 200,
      },
    },
    documentRow: {
      flex: 1,
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      marginBottom: theme.tokens.spacing[4],
    },
    formContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    handle: {
      backgroundColor: theme.colors.background,
      borderTopEndRadius: theme.tokens.spacing[3],
      borderTopStartRadius: theme.tokens.spacing[3],
    },
    handleIndicator: {
      backgroundColor: theme.colors.onBackground,
    },
    title: {
      marginBottom: theme.tokens.spacing[4],
    },
  }
})

export default ScanDocumentAction
