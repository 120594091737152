import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import { Stack, useNavigation } from 'expo-router'
import { useStyles } from 'react-native-unistyles'
import FosterDetailHeader from '../../../../../components/navigation/FosterDetailHeader'

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <FosterDetailHeader {...props} />
}

const FosterLayout = () => {
  const navigation = useNavigation()

  const { theme } = useStyles()

  navigation.setOptions({
    headerShown: false,
  })

  return (
    <Stack
      screenOptions={{
        contentStyle: { backgroundColor: theme.colors.background },
        header: HeaderComponent,
        headerBackVisible: true,
        freezeOnBlur: true,
      }}
    />
  )
}

export default FosterLayout
