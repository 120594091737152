import { useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../store/useAppStore'
import Page from '../shared/Page'
import OrganizationInfoCard from './cards/OrganizationInfoCard'
import OrganizationMedicalInfoCard from './cards/OrganizationMedicalInfoCard'
import OrganizationVaccinesCard from './cards/OrganizationVaccinesCard'

const OrganizationSettings = () => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()

  const currentOrganization = useAppStore.use.currentOrganization()

  useEffect(() => {
    navigation.setOptions({
      title: currentOrganization.name,
    })
  })

  return (
    <Page viewId="organization-settings">
      <OrganizationInfoCard />
      <View style={styles.medicalContainer}>
        <OrganizationVaccinesCard />
        <OrganizationMedicalInfoCard />
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    medicalContainer: {
      flexDirection: {
        md: 'column',
        lg: 'row',
      },
      gap: theme.tokens.spacing[4],
    },
  }
})

export default OrganizationSettings
