import { useState } from 'react'
import { Pressable, View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import SettingsModal from './SettingsModal'
import UserAvatar from './UserAvatar'

const UserAvatarButton = () => {
  const { styles } = useStyles(stylesheet)

  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  return (
    <View testID="avatar-settings-button">
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          showModal()
        }}
        style={styles.root}
      >
        <UserAvatar size={30} />
      </Pressable>
      <SettingsModal hideModal={hideModal} visible={modalVisible} />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      padding: theme.tokens.spacing[4],
    },
  }
})

export default UserAvatarButton
