import { StyleProp, TextStyle, View } from 'react-native'
import { Text } from 'react-native-paper'
import { VariantProp } from 'react-native-paper/lib/typescript/components/Typography/types'
import { isBold, stripMarkers } from '../../utils/strings'

const StyledSearchTitle = ({
  id,
  text,
  style,
  variant,
}: {
  id: string
  text: string
  style?: StyleProp<TextStyle>
  variant: VariantProp<Text>
}) => {
  return (
    <View key={id + '-styled-view'} style={{ flexDirection: 'row' }}>
      {text.split(' ').map((part, index) => (
        <Text key={id + text + index}>
          <Text
            style={{
              fontWeight: isBold(part) ? 'bold' : 'light',
              ...(typeof style === 'object' ? style : {}),
            }}
            variant={variant}
          >
            {stripMarkers(part)}
          </Text>
          {index !== text.split(' ').length - 1 && (
            <Text variant={variant}> </Text>
          )}
        </Text>
      ))}
    </View>
  )
}

export default StyledSearchTitle
