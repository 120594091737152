import debounce from 'debounce'
import { useCallback } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import useComponentWillUnmount from './useComponentWillUnmount'

interface Props {
  form: UseFormReturn<any, any, undefined>
  defaultValues: any
  onSave: any
  interval?: number // milliseconds
  onReset?: () => void
}

const useAutoSave = ({
  form: methods,
  defaultValues,
  onSave,
  interval = 500,
}: Props) => {
  useComponentWillUnmount(() => {
    if (methods.formState.isDirty) {
      methods.handleSubmit(onSave)()
    }
  })

  // Save if this function is called and then not called again within <interval>ms
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(() => {
      methods.handleSubmit(onSave)()
    }, interval),
    []
  )

  // Watch all the data, provide with defaultValues from server
  // this way we know if the new data came from server or were actually edited by user
  const watchedData = useWatch({
    control: methods.control,
    defaultValue: defaultValues,
  })

  useDeepCompareEffect(() => {
    if (methods.formState.isDirty) {
      debouncedSave()
      debounce(() => {
        methods.reset(defaultValues, {
          keepDirtyValues: false,
        })
      }, interval + 20000)
    }
  }, [watchedData])
}

export default useAutoSave
