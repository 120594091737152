const spacing = {
  1: 2,
  2: 4,
  3: 8,
  4: 16,
  5: 24,
  6: 32,
  7: 40,
  8: 48,
  9: 56,
  10: 64,
}

const tokens = {
  spacing,
  containerBorderRadius: 8,
  drawerWidth: 260,
  tabBarIconSize: 24,
}

export default tokens
