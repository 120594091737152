import { Platform } from 'react-native'

export default async function prepareFileUpload({
  fields = {},
  fileBlob,
  fileName,
  mimeType,
  uri,
}: {
  fields?: Record<string, string>
  fileBlob?: Blob

  fileName?: string
  mimeType?: string
  uri: string
}) {
  const formData = new FormData()

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })

  if (Platform.OS === 'web') {
    if (!fileBlob) {
      throw new Error('fileBlob is required on web')
    }

    formData.append('file', fileBlob, fileName)
  } else {
    // @ts-expect-error - uri is not in the FormData type, but this is valid for react native
    formData.append('file', {
      uri: uri,
      name: fileName,
      type: mimeType,
    })
  }

  return formData
}
