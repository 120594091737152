import { isValid, parse, startOfDay } from 'date-fns'
import { useRef, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form'
import {
  Platform,
  TextInput as RNTextInput,
  View,
  ViewStyle,
} from 'react-native'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import {
  HelperText,
  Modal,
  Portal,
  TextInput,
  TextInputProps,
} from 'react-native-paper'
import DateTimePicker from 'react-native-ui-datepicker'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Skeleton from '../Skeleton'
import { getErrorField } from './formUtils'

interface Props<C extends FieldValues, E extends FieldErrors> {
  fieldName: Path<C>
  required: boolean
  errors?: E
  control: Control<C, any>
  isLoading?: boolean
  onBlur?: () => void
}

const FormFieldDate = <C extends FieldValues, E extends FieldErrors>({
  fieldName,
  required,
  errors,
  control,
  style,
  isLoading,
  onBlur,
  ...props
}: Props<C, E> & TextInputProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const { styles } = useStyles(stylesheet)
  const { theme } = useStyles()
  const inputRef = useRef<RNTextInput>(null)

  return (
    <View style={[style as ViewStyle, styles.root]}>
      <Skeleton isLoading={isLoading}>
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { onChange, value } }) => (
            <View>
              <TextInput
                testID={`${fieldName}-dateInput`}
                {...props}
                blurOnSubmit
                error={!!getErrorField(fieldName, errors)}
                mode="outlined"
                onBlur={() => {
                  if ((value as unknown as Date) instanceof Date) {
                    onChange(startOfDay(value))
                  } else if (typeof value === 'string') {
                    const text = value?.trim()

                    if (text === '') {
                      onChange(null)
                      return
                    }
                    const date = parseFlexibleDate(text)
                    if (date) {
                      onChange(startOfDay(date))
                    } else {
                      onChange(text)
                    }
                  }

                  onBlur?.()
                }}
                onChangeText={onChange}
                ref={inputRef}
                right={
                  <TextInput.Icon
                    color={theme.colors.secondary}
                    icon="calendar"
                    onPress={() => {
                      setShowDatePicker(true)
                    }}
                    testID={`calendar-icon-${fieldName}`}
                  />
                }
                style={styles.container}
                value={displayValue(value)}
              />
              {Platform.OS === 'web' && showDatePicker && (
                <Portal>
                  <Modal
                    contentContainerStyle={styles.webCalendarContainer}
                    onDismiss={() => setShowDatePicker(false)}
                    visible={showDatePicker}
                  >
                    <View style={styles.webCalendarView}>
                      <DateTimePicker
                        date={value || new Date()}
                        mode="single"
                        onChange={(params) => {
                          if (params.date) {
                            setShowDatePicker(false)
                            onChange(new Date(params.date.toLocaleString()))
                            onBlur?.()
                            inputRef.current?.blur()
                          }
                        }}
                      />
                    </View>
                  </Modal>
                </Portal>
              )}
              {(Platform.OS === 'ios' || Platform.OS === 'android') && (
                <DateTimePickerModal
                  date={(value as any) instanceof Date ? value : new Date()}
                  isVisible={showDatePicker}
                  mode="date"
                  onCancel={() => setShowDatePicker(false)}
                  onConfirm={(date) => {
                    onChange(date)
                    setShowDatePicker(false)
                    inputRef.current?.blur()
                  }}
                />
              )}
              {getErrorField(fieldName, errors) && (
                <HelperText
                  padding="normal"
                  testID={`${fieldName}-error`}
                  type="error"
                  visible={!!getErrorField(fieldName, errors)}
                >
                  {getErrorField(fieldName, errors)?.message as string}
                </HelperText>
              )}
            </View>
          )}
          rules={{
            required: required,
          }}
        />
      </Skeleton>
    </View>
  )
}

function displayValue(value: Date | null) {
  if (!value) return ''

  try {
    return value.toLocaleDateString()
  } catch {
    return value.toString()
  }
}

const stylesheet = createStyleSheet((theme) => ({
  container: {
    backgroundColor: theme.colors.surface,
  },
  root: {
    height: 50,
    marginBottom: theme.tokens.spacing[2],
  },
  webCalendarContainer: {
    alignSelf: 'center',
    width: '45%',
  },
  webCalendarView: {
    backgroundColor: theme.colors.surface,
    flex: 1,
    padding: 10,
  },
}))

function parseFlexibleDate(dateString: string): Date | null {
  const formats = [
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'MM/dd/yyyy',
    'dd/MM/yyyy',
    'yyyy/MM/dd',
    'dd.MM.yyyy',
    'MM.dd.yyyy',
    'MMMM d, yyyy',
    'd MMMM yyyy',
    'yyyy-MM-dd HH:mm:ss',
    'dd-MM-yyyy HH:mm:ss',
  ]

  for (const format of formats) {
    const date = parse(dateString, format, new Date())
    if (isValid(date)) {
      return date
    }
  }

  return null
}

export default FormFieldDate
