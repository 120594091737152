import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'

const useCurrentOrganization = () => {
  const currentOrganization = useAppStore.use.currentOrganization()
  const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

  const currentUserQuery = trpc.user.current.useQuery({
    organizationId: currentOrganization.id,
  })

  const organizations = currentUserQuery.data?.organizations.map(
    (org) => org.organization
  )

  if (organizations?.length && !currentOrganization.id) {
    setCurrentOrganization({
      id: organizations?.[0].id,
      logo: organizations?.[0].logo || '',
      name: organizations?.[0].name,
    })
  }

  return { currentOrganization, setCurrentOrganization }
}

export default useCurrentOrganization
