import { FieldError, FieldErrors } from 'react-hook-form'

export function getErrorField<E extends FieldErrors>(
  fieldName: string,
  errors?: E
) {
  // Use type assertion to bypass the index signature error
  const error = errors?.[fieldName as keyof E]
  if (error) {
    return error
  }

  // Iterate over the object keys so we can check the nested ref.name field
  for (const key in errors) {
    const typedKey = key as keyof E

    // Type assertion for the ref property
    const potentialError = errors[typedKey] as { ref?: { name: string } }

    if (potentialError.ref?.name === fieldName) {
      return potentialError as FieldError
    }
  }

  return undefined
}

export function prepareFormForSubmission<T>(data: T) {
  const cleanedData: T = {
    ...data,
  }

  for (const key in cleanedData) {
    if (cleanedData[key as keyof typeof cleanedData] === '') {
      // @ts-expect-error - our zod validation layer will ensure the UI can't submit a bad value on our required fields,
      // so the only fields that we'll actually be setting to null are the optional fields that we're unsetting here.
      cleanedData[key as keyof typeof cleanedData] = null
    }
  }

  return cleanedData
}
