import FosterService from '../../services/fosterService'
import SaveStatus from '../shared/SaveStatus'

const FosterDetailHeaderContent = () => {
  const { fosterId } = FosterService.useCurrentFoster()

  const { foster } = FosterService.useFoster(fosterId)
  const isSaving = FosterService.useIsSaving()

  return (
    <SaveStatus
      isSaving={isSaving}
      updatedAt={foster?.updatedAt}
      updatedBy={`${foster?.updatedBy.firstName} ${foster?.updatedBy.lastName}`}
    />
  )
}

export default FosterDetailHeaderContent
