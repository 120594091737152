import { Link, useNavigation } from 'expo-router'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { DataTable, ProgressBar, Text } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import HeaderContent from '../../../components/users/list/UserListHeaderContent'
import UserService from '../../../services/userService'
import { useAppStore } from '../../../store/useAppStore'
import formatDate from '../../../utils/formatDate'

const Users = () => {
  const navigation = useNavigation()
  const includeDeactivated = useAppStore.use.includeDeactivated()
  const { users, error, isLoading } = UserService.useUserList()
  const [page, setPage] = useState<number>(0)
  const { theme, styles } = useStyles(stylesheet)

  const itemHeight = 47.5 // Height of each item including margins/paddings
  // 104 accounts for the height of the header, footer, and the need to keep the footer padded
  const itemsPerPage =
    Math.floor((UnistylesRuntime.screen.height - 104) / itemHeight) - 2

  const from = page * itemsPerPage
  const to = Math.min((page + 1) * itemsPerPage, users?.length || 0)

  useEffect(() => {
    navigation.setOptions({
      title: 'Users',
      headerRight: HeaderRight,
    })
  }, [navigation])

  return (
    <View>
      <DataTable style={styles.dataTable} testID="data-table">
        <DataTable.Header style={styles.header}>
          <DataTable.Title>First Name</DataTable.Title>
          <DataTable.Title>Last Name</DataTable.Title>
          <DataTable.Title style={styles.hiddenLtMd}>Email</DataTable.Title>
          {includeDeactivated && (
            <DataTable.Title style={styles.hiddenLtSm}>
              Deactivated On
            </DataTable.Title>
          )}
        </DataTable.Header>
        <View>
          <ProgressBar
            color={theme.colors.primary}
            indeterminate
            style={styles.loading}
            testID="user-progress-bar"
            visible={isLoading}
          />
        </View>
        {error && !isLoading && (
          <Text style={styles.errorMessage} testID="user-list-error">
            Error loading users.
          </Text>
        )}

        {!error && (
          <>
            {users?.slice(from, to).map((user, i) => (
              <Link
                asChild
                href={`/users/${user.id}`}
                key={user.id}
                push
                style={{ width: '100%' }}
              >
                <DataTable.Row
                  style={styles.row(user, i, to)}
                  testID={`user-row-${user.id}`}
                >
                  <DataTable.Cell>{user.firstName}</DataTable.Cell>
                  <DataTable.Cell>{user.lastName}</DataTable.Cell>
                  <DataTable.Cell style={styles.hiddenLtMd}>
                    {user.email}
                  </DataTable.Cell>
                  {includeDeactivated && (
                    <DataTable.Cell style={styles.hiddenLtSm}>
                      {formatDate(user.deactivatedAt)}
                    </DataTable.Cell>
                  )}
                </DataTable.Row>
              </Link>
            ))}
          </>
        )}

        {!isLoading && (
          <DataTable.Pagination
            label={`${from + 1}-${to} of ${users?.length || 0}`}
            numberOfItemsPerPage={itemsPerPage}
            numberOfItemsPerPageList={[itemsPerPage]}
            numberOfPages={Math.ceil((users?.length || 0) / itemsPerPage)}
            onPageChange={(p) => setPage(p)}
            page={page}
            showFastPaginationControls
          />
        )}
      </DataTable>
    </View>
  )
}

const HeaderRight = () => <HeaderContent />

const stylesheet = createStyleSheet((theme) => ({
  dataTable: {
    padding: theme.tokens.spacing[4],
  },
  errorMessage: {
    color: theme.colors.error,
    fontWeight: 'bold',
    paddingBottom: theme.tokens.spacing[4],
    paddingTop: theme.tokens.spacing[4],
    textAlign: 'center',
  },
  header: {
    backgroundColor: theme.colors.surface,
    borderColor: theme.colors.cardBorder,
    borderTopLeftRadius: theme.tokens.containerBorderRadius,
    borderTopRightRadius: theme.tokens.containerBorderRadius,
    borderWidth: 1,
  },
  hiddenLtMd: {
    display: {
      xs: 'none',
      md: 'flex',
    },
  },
  hiddenLtSm: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
  },
  loading: {
    position: 'absolute',
    top: -4,
  },
  row: (user, i, to) => ({
    backgroundColor: user.deactivatedAt
      ? theme.colors.disabledRow
      : theme.colors.surface,
    width: '100%',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: theme.colors.cardBorder,
    borderBottomLeftRadius:
      i === to - 1 ? theme.tokens.containerBorderRadius : 0,
    borderBottomRightRadius:
      i === to - 1 ? theme.tokens.containerBorderRadius : 0,
  }),
}))

export default Users
