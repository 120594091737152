import { Link, router } from 'expo-router'
import { View } from 'react-native'
import { Button, DataTable } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'

const OrganizationVaccinesCard = () => {
  const { styles } = useStyles(stylesheet)

  const currentOrganizationId = useAppStore.use.currentOrganization().id

  const vaccineListQuery = trpc.vaccine.list.useQuery(
    {
      organizationId: currentOrganizationId || '',
    },
    {
      enabled: currentOrganizationId !== '',
    }
  )

  return (
    <Card title="Vaccines">
      <View>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={styles.nameColumn}>Name</DataTable.Title>
            <DataTable.Title style={styles.speciesColumn}>
              Species
            </DataTable.Title>
            <DataTable.Title style={styles.minAgeColumn}>
              Min Age
            </DataTable.Title>
          </DataTable.Header>
        </DataTable>
        {vaccineListQuery.data?.map((vaccine) => (
          <DataTable.Row
            key={vaccine.id}
            onPress={() => {
              router.push(
                `/organization/${currentOrganizationId}/vaccines/${vaccine.id}`
              )
            }}
          >
            <DataTable.Cell style={styles.nameColumn}>
              {vaccine.name}
            </DataTable.Cell>
            <DataTable.Cell style={styles.speciesColumn}>
              {vaccine.species}
            </DataTable.Cell>
            <DataTable.Cell style={styles.minAgeColumn}>
              {vaccine.minAgeWeeks}
            </DataTable.Cell>
          </DataTable.Row>
        ))}
        <Link
          asChild
          href={`/organization/${currentOrganizationId}/vaccines/new`}
        >
          <Button mode="contained" style={styles.button}>
            Add
          </Button>
        </Link>
      </View>
    </Card>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    button: {
      marginTop: theme.tokens.spacing[4],
      width: 120,
    },
    minAgeColumn: {
      flex: 0.4,
    },
    nameColumn: {
      flex: 1,
    },
    speciesColumn: {
      flex: 0.4,
    },
  }
})

export default OrganizationVaccinesCard
