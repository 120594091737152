import { useCallback } from 'react'
import FosterService from '../../services/fosterService'
import Page from '../shared/Page'
import FosterAdoptionCard from './cards/FosterAdoptionsCard'
import FosterDocumentsCard from './cards/FosterDocumentsCard'
import FosterHealthCard from './cards/FosterHealthCard'
import FosterNotesCard from './cards/FosterNotesCard'
import FosterProfileCard from './cards/FosterProfileCard'
import useFosterForm from './useFosterForm'

const FosterPage = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const { refreshFoster } = FosterService.useFoster(fosterId)

  const form = useFosterForm()

  const { control, formState } = form

  const onRefresh = useCallback(async () => {
    await refreshFoster()
  }, [refreshFoster])

  return (
    <Page onRefresh={onRefresh} viewId="foster-detail-scroll-view">
      <FosterProfileCard control={control} formState={formState} />
      <FosterHealthCard control={control} formState={formState} />
      <FosterNotesCard control={control} formState={formState} />
      <FosterAdoptionCard control={control} formState={formState} />
      <FosterDocumentsCard />
    </Page>
  )
}

export default FosterPage
