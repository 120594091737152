import z from 'zod'

const fosterFormVaccinationSchema = z.object({
  id: z.string().uuid(),
  vaccineId: z.string().uuid(),
  fosterId: z.string().uuid(),
  name: z.string().nullable().optional(),
  dueDate: z.date().nullable().optional(),
  givenDate: z.date().nullable().optional(),
  longTermBoosterCadence: z.number().nullable().optional(),
  givenBy: z.string().nullable().optional(),
})

const fosterFormSchema = z.object({
  birthDate: z.date().nullable().optional(),
  breed: z.string().nullable().optional(),
  clearFecalTestDate: z.date().nullable().optional(),
  comboTestingCompletedDate: z.date().nullable().optional(),
  currentFood: z.string().nullable().optional(),
  deceasedDate: z.date().nullable().optional(),
  fecalFollowUpDate: z.date().nullable().optional(),
  felvPositive: z.boolean(),
  fivPositive: z.boolean(),
  fixedBeforeIntake: z.boolean(),
  fixedOnDate: z.date().nullable().optional(),
  gender: z.enum(['Male', 'Female']).nullable().optional(),
  heartwormPositive: z.boolean(),
  heartwormTestingCompletedDate: z.date().nullable().optional(),
  history: z.string().nullable().optional(),
  intakeDate: z.date().nullable().optional(),
  isAdopted: z.boolean(),
  isHospice: z.boolean(),
  microchipId: z.string().nullable().optional(),
  name: z.string().min(1),
  nextComboMedDue: z.date().nullable().optional(),
  nextFleaTickMedDue: z.date().nullable().optional(),
  nextHeartwormPreventionDue: z.date().nullable().optional(),
  notes: z.string().nullable().optional(),
  positiveFecalTestDate: z.date().nullable().optional(),
  privateNotes: z.string().nullable().optional(),
  readyForAdoption: z.boolean(),
  species: z.enum(['Dog', 'Cat']).nullable().optional(),
  training: z.string().nullable().optional(),
  userId: z.string().uuid(),
  vaccinations: z.array(fosterFormVaccinationSchema),
  weight: z.string().nullable().optional(),
})

export type FosterForm = z.infer<typeof fosterFormSchema>

export default fosterFormSchema
