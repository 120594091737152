import { datadogLogs } from '@datadog/browser-logs'
import * as Application from 'expo-application'

if (
  process.env.NODE_ENV === 'production' &&
  !!process.env.EXPO_PUBLIC_WEB_DATADOG_CLIENT_TOKEN
) {
  datadogLogs.init({
    env: process.env.NODE_ENV || '',
    clientToken: process.env.EXPO_PUBLIC_WEB_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    ...(Application.nativeApplicationVersion
      ? {
          version: Application.nativeApplicationVersion,
        }
      : {}),
  })
}

export default datadogLogs.logger
