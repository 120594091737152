import { useFocusEffect, useRouter } from 'expo-router'

const NotFound = () => {
  const router = useRouter()

  useFocusEffect(() => {
    // Call the replace method to redirect to a new route without adding to the history.
    // We do this in a useFocusEffect to ensure the redirect happens every time the screen
    // is focused.
    router.replace('/fosters')
  })
}

export default NotFound
