import { View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import OrganizationAvatar from './OrganizationAvatar'

interface Props {
  id: string
  logo: string | undefined | null
  name: string
  onPress?: () => void
}

const OrganizationButton = ({ logo, name, onPress }: Props) => {
  const { styles } = useStyles(stylesheet)

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless={true}
      onPress={onPress}
      style={styles.root}
    >
      <View style={styles.rowContainer}>
        <OrganizationAvatar logo={logo} name={name} size={40} />
        <View>
          <Text style={styles.title}>{name}</Text>
        </View>
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      borderRadius: theme.tokens.spacing[4],
      padding: theme.tokens.spacing[4],
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    title: {
      fontWeight: 'bold',
    },
  }
})

export default OrganizationButton
