import { StateCreator } from 'zustand'
import Species from '../enums/species'

const defaultState = {
  isActive: true,
  isDirty: false,
  filters: {
    currentlyFostered: false,
    includeDeceased: false,
    readyForAdoption: false,
    species: undefined as Species | undefined,
    viewAll: false,
  },
}

type BasicFilters = typeof defaultState

export interface BasicFiltersSlice {
  basicFilters: BasicFilters
  setBasicFilters: (filters: BasicFilters) => void
  resetBasicFilters: () => void
}

export const createBasicFilterSlice: StateCreator<BasicFiltersSlice> = (
  set
) => ({
  basicFilters: defaultState,
  setBasicFilters: ({
    isActive,
    filters: {
      currentlyFostered,
      includeDeceased,
      readyForAdoption,
      species,
      viewAll,
    },
  }: BasicFilters) => {
    set({
      basicFilters: {
        isActive,
        // isDirty should be true if any of the filters are different from the default
        isDirty: !(
          currentlyFostered === defaultState.filters.currentlyFostered &&
          includeDeceased === defaultState.filters.includeDeceased &&
          readyForAdoption === defaultState.filters.readyForAdoption &&
          species === defaultState.filters.species &&
          viewAll === defaultState.filters.viewAll
        ),
        filters: {
          currentlyFostered,
          includeDeceased,
          readyForAdoption,
          species,
          viewAll,
        },
      },
    })
  },
  resetBasicFilters: () => {
    set((state) => ({
      basicFilters: {
        ...defaultState,
        isActive: state.basicFilters.isActive,
      },
    }))
  },
})
