import { StateCreator } from 'zustand'

const defaultState = {
  selection: {
    items: [] as string[],
  },
}

type BulkSelect = typeof defaultState

export interface BulkSelectSlice {
  bulkSelect: BulkSelect
  setBulkSelect: (selection: BulkSelect) => void
  resetBulkSelect: () => void
}

export const createBulkSelectSlice: StateCreator<BulkSelectSlice> = (set) => ({
  bulkSelect: defaultState,
  setBulkSelect: ({ selection: { items } }: BulkSelect) => {
    set({
      bulkSelect: { selection: { items } },
    })
  },
  resetBulkSelect: () => {
    set(() => ({
      bulkSelect: defaultState,
    }))
  },
})
