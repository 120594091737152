import { useNavigation } from 'expo-router'
import { View } from 'moti'
import { useState } from 'react'
import { Appbar, Searchbar } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

const headerXPadding = 16

interface Props {
  onChangeText: (text: string) => void
}

const SearchHeader = (props: Props) => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()

  const [value, setValue] = useState('')

  return (
    <Appbar.Header elevated={false} style={styles.root}>
      <View style={styles.header}>
        <Searchbar
          autoFocus={true}
          icon="arrow-left"
          onChangeText={(text) => {
            setValue(text)
            props.onChangeText(text)
          }}
          onIconPress={() => {
            navigation.goBack()
          }}
          placeholder="Search"
          testID="search-bar"
          value={value}
        />
      </View>
    </Appbar.Header>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    header: {
      flex: 1,
    },
    root: {
      backgroundColor: theme.colors.background,
      paddingLeft: headerXPadding,
      paddingRight: headerXPadding,
    },
  }
})

export default SearchHeader
