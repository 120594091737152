import { useLocalSearchParams } from 'expo-router'
import OrganizationSettingsPage from '../../../../components/organization/OrganizationSettings'
import { useAppStore } from '../../../../store/useAppStore'
import trpc from '../../../../utils/trpc'

const OrganizationSettings = () => {
  const organizationId = useLocalSearchParams<{ organizationId: 'string' }>()
    .organizationId

  const currentOrganization = useAppStore.use.currentOrganization()
  const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

  if (!organizationId) {
    return null
  }

  const organization = trpc.organization.byId.useQuery({
    organizationId: organizationId,
  })

  if (
    currentOrganization.id !== organizationId &&
    organization.data?.id === organizationId
  ) {
    setCurrentOrganization({
      id: organizationId,
      logo: organization.data?.logo || '',
      name: organization.data?.name || '',
    })
  }

  return <OrganizationSettingsPage />
}

export default OrganizationSettings
