import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form'
import { View, ViewStyle } from 'react-native'
import { HelperText, TextInputProps } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Skeleton from '../Skeleton'
import { getErrorField } from './formUtils'
import TextInput from './TextInput'

interface Props<C extends FieldValues, E extends FieldErrors> {
  fieldName: Path<C>
  required: boolean
  errors?: E
  control: Control<C, any>
  isLoading?: boolean
  onBlur?: () => void
}

const FormFieldText = <C extends FieldValues, E extends FieldErrors>({
  fieldName,
  required,
  errors,
  control,
  isLoading,
  style,
  onBlur,
  ...props
}: Props<C, E> & TextInputProps) => {
  const { styles } = useStyles(stylesheet)

  return (
    <View style={[style as ViewStyle, styles.root]}>
      <Skeleton isLoading={isLoading}>
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { onChange, onBlur: formOnBlur, value } }) => (
            <View style={styles.container}>
              <TextInput
                accessibilityHint="This is a text input field"
                accessibilityLabel="Text input field"
                testID={`${fieldName}-textInput`}
                {...props}
                contentStyle={styles.container}
                label={props.label as string}
                // error={!!getErrorField(fieldName, errors)}
                multiline={props.multiline}
                onBlur={() => {
                  onBlur?.()
                  formOnBlur()
                }}
                onChangeText={onChange}
                value={value?.toString() || ''}
              />
              {getErrorField(fieldName, errors) && (
                <HelperText
                  padding="normal"
                  type="error"
                  visible={!!getErrorField(fieldName, errors)}
                >
                  {getErrorField(fieldName, errors)?.message as string}
                </HelperText>
              )}
            </View>
          )}
          rules={{
            required: required,
          }}
        />
      </Skeleton>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  container: {
    backgroundColor: theme.colors.surface,
    flexWrap: 'nowrap',
  },
  root: {
    marginBottom: theme.tokens.spacing[2],
  },
}))

export default FormFieldText
