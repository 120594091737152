import { View } from 'moti'
import Animated, {
  SharedValue,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

interface Props {
  isExpanded: SharedValue<boolean>
  children: React.ReactNode
  viewKey: string
  style?: any
  duration?: number
}

const AccordionItem = ({
  isExpanded,
  children,
  viewKey,
  style,
  duration = 150,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const height = useSharedValue(0)

  const derivedHeight = useDerivedValue(() =>
    withTiming(height.value * Number(isExpanded.value), {
      duration,
    })
  )

  console.log('derivedHeight', derivedHeight.value)

  const bodyStyle = useAnimatedStyle(() => ({
    height: derivedHeight.value,
  }))

  return (
    <Animated.View
      key={`accordionItem_${viewKey}`}
      style={[styles.animatedView, bodyStyle, style]}
    >
      <View
        onLayout={(e) => {
          height.value = e.nativeEvent.layout.height
        }}
        style={styles.wrapper}
      >
        {children}
      </View>
    </Animated.View>
  )
}

export default AccordionItem

const stylesheet = createStyleSheet(() => {
  return {
    animatedView: {
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      position: 'absolute',
      width: '100%',
    },
  }
})
