import FosterAdoptionCard from '../../../../../../components/fosters/cards/FosterAdoptionsCard'
import { useFosterFormContext } from '../../../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../../../components/fosters/FosterTab'

const FosterAdoptionsTab = () => {
  const { control, formState } = useFosterFormContext()

  return (
    <FosterTab viewId="foster-adoptions-tab">
      <FosterAdoptionCard control={control} formState={formState} />
    </FosterTab>
  )
}

export default FosterAdoptionsTab
