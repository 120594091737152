import { useEffect } from 'react'
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import FormFieldDate from '../../../form/FormFieldDate'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldGender from '../../../fosters/cards/formFields/FormFieldGender'
import FormFieldSpecies from '../../../fosters/cards/formFields/FormFieldSpecies'
import {
  AdvancedFilterField,
  AdvancedFilterFormValues,
  AdvancedFilterOperator,
  AdvancedFilterType,
} from '../AdvancedFilterTypes'

const AdvancedFilterValueField = ({
  index,
  control,
  errors,
  selectedField,
  selectedOperator,
  setValue,
  dropdownStyle,
  textViewStyle,
}: {
  index: number
  control: Control<AdvancedFilterFormValues>
  errors: FieldErrors<AdvancedFilterFormValues>
  selectedField: AdvancedFilterField | undefined
  selectedOperator: AdvancedFilterOperator | string
  setValue: UseFormSetValue<AdvancedFilterFormValues>
  dropdownStyle: StyleProp<ViewStyle>
  textViewStyle: StyleProp<TextStyle>
}) => {
  const isExistsOperator = [
    AdvancedFilterOperator.Equals,
    AdvancedFilterOperator.NotEquals,
  ].includes(selectedOperator as AdvancedFilterOperator)

  useEffect(() => {
    if (isExistsOperator && selectedField?.type !== AdvancedFilterType.Enum) {
      setValue(`filters.${index}.value`, null)
    }
  }, [index, isExistsOperator, selectedField?.type, setValue])

  if (selectedOperator === '') {
    return null
  }

  if (isExistsOperator && selectedField?.type !== AdvancedFilterType.Enum) {
    return null
  }

  switch (selectedField?.type) {
    case AdvancedFilterType.Enum:
      switch (selectedField.field) {
        case 'species':
          return (
            <FormFieldSpecies
              control={control}
              errors={errors}
              fieldName={`filters.${index}.value`}
              style={dropdownStyle}
            />
          )
        case 'gender':
          return (
            <FormFieldGender
              control={control}
              errors={errors}
              fieldName={`filters.${index}.value`}
              style={dropdownStyle}
            />
          )
        default:
          return null
      }
    case AdvancedFilterType.String:
      return (
        <FormFieldText
          blurOnSubmit
          control={control}
          fieldName={`filters.${index}.value`}
          label="Enter value"
          required
          style={textViewStyle}
        />
      )
    case AdvancedFilterType.Date:
      return (
        <FormFieldDate
          control={control}
          fieldName={`filters.${index}.value`}
          label="Select date"
          required
          style={textViewStyle}
        />
      )
    case AdvancedFilterType.Boolean:
      return null
    default:
      return null
  }
}

export default AdvancedFilterValueField
