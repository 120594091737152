import { useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form'
import { View } from 'react-native'
import { Dropdown } from 'react-native-element-dropdown'
import { DropdownProps } from 'react-native-element-dropdown/lib/typescript/components/Dropdown/model'
import { HelperText, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Skeleton from '../Skeleton'
import { getErrorField } from './formUtils'

type data = { label: string; value: string }

interface Props<C extends FieldValues, E extends FieldErrors> {
  control: Control<C, any>
  data: data[]
  errors?: E
  fieldName: Path<C>
  isLoading?: boolean
  label: string
  required: boolean
}

const FormFieldSelect = <C extends FieldValues, E extends FieldErrors>({
  control,
  data,
  errors,
  fieldName,
  label,
  required,
  style,
  isLoading,
  ...props
}: Props<C, E> & Partial<DropdownProps<data>>) => {
  const { styles, theme } = useStyles(stylesheet)

  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState<data | null>(null)

  const renderLabel = () => {
    if (selectedDropdownValue || control._formValues[fieldName]) {
      return <Text style={styles.label}>{label}</Text>
    }
    return null
  }

  return (
    <View style={[style, styles.root]}>
      <Skeleton isLoading={isLoading}>
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { onChange, onBlur, value } }) => (
            <View
              style={[
                style,
                { marginTop: 5, zIndex: 100, position: 'relative' },
              ]}
            >
              {renderLabel()}
              <Dropdown
                activeColor={theme.colors.primaryContainer}
                containerStyle={styles.dropdownContainer}
                data={data}
                dropdownPosition="auto"
                iconColor={
                  getErrorField(fieldName, errors)
                    ? theme.colors.error
                    : theme.colors.onBackground
                }
                inverted={false}
                itemContainerStyle={styles.dropdownItemContainer}
                itemTestIDField={`${fieldName}-dropdown-item`}
                itemTextStyle={styles.dropdownItemText}
                labelField="label"
                onBlur={onBlur}
                onChange={(selected) => {
                  setSelectedDropdownValue(selected)
                  onChange(selected.value)
                }}
                placeholder={label}
                placeholderStyle={[
                  styles.placeholder,
                  getErrorField(fieldName, errors) && {
                    color: theme.colors.error,
                  },
                ]}
                selectedTextProps={{ testID: `${fieldName}-dropdown-text` }}
                selectedTextStyle={styles.dropdownSelectedText}
                style={[
                  styles.dropdown,
                  getErrorField(fieldName, errors) && {
                    borderColor: theme.colors.error,
                    borderWidth: 2,
                  },
                ]}
                testID={`${fieldName}-dropdown`}
                value={value ? data.find((d) => d.value === value) : ''}
                valueField="value"
                {...props}
              />
              {getErrorField(fieldName, errors) && (
                <HelperText
                  padding="normal"
                  type="error"
                  visible={!!getErrorField(fieldName, errors)}
                >
                  {getErrorField(fieldName, errors)?.message as string}
                </HelperText>
              )}
            </View>
          )}
          rules={{
            required: required,
          }}
        />
      </Skeleton>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    dropdown: {
      backgroundColor: theme.colors.surface,
      borderColor: theme.colors.outline,
      borderRadius: 4,
      borderWidth: 1,
      height: 50,
      paddingHorizontal: 12,
    },
    dropdownContainer: {
      backgroundColor: theme.colors.background,
      borderColor: theme.colors.outline,
      maxHeight: '80%',
    },
    dropdownItemContainer: {
      backgroundColor: theme.colors.background,
    },
    dropdownItemText: {
      color: theme.colors.onBackground,
    },
    dropdownSelectedText: {
      backgroundColor: theme.colors.surface,
      color: theme.colors.onBackground,
    },
    // The values in this style are intentionally hardcoded to match the design of react-native-paper
    // this allows the component to be used as a near-visual-drop-in replacement for react-native-paper's text input
    label: {
      backgroundColor: theme.colors.surface,
      color: theme.colors.onSurfaceVariant,
      fontSize: 12,
      left: 5,
      paddingHorizontal: theme.tokens.spacing[2],
      position: 'absolute',
      top: -8,
      zIndex: 999,
    },
    placeholder: {
      color: theme.colors.onSurface,
    },
    root: {
      marginBottom: theme.tokens.spacing[2],
    },
  }
})

export default FormFieldSelect
