import { View } from 'moti'
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form'
import { IconButton } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FormFieldSelect from '../../form/FormFieldSelect'
import {
  AdvancedFilterField,
  AdvancedFilterFormValues,
} from './AdvancedFilterTypes'
import AdvancedFilterOperatorField from './fields/AdvancedFilterOperatorField'
import AdvancedFilterValueField from './fields/AdvancedFilterValueField'

interface Props {
  control: Control<AdvancedFilterFormValues>
  errors: FieldErrors<AdvancedFilterFormValues>
  id: string
  index: number
  remove: (index: number) => void
  fields: AdvancedFilterField[]
  setValue: UseFormSetValue<AdvancedFilterFormValues>
}

const AdvancedFilterRow: React.FC<Props> = ({
  control,
  errors,
  index,
  remove,
  fields,
  setValue,
  id,
}) => {
  const { styles } = useStyles(stylesheet)

  const fieldOptions = fields.map((f) => ({ label: f.title, value: f.field }))

  const selectedFieldName = useWatch({
    control,
    name: `filters.${index}.field`,
  })

  const selectedOperator = useWatch({
    control,
    name: `filters.${index}.operator`,
  })

  const selectedField = fields.find((f) => f.field === selectedFieldName)

  return (
    <View key={id} style={styles.row}>
      <View style={styles.rowColumn}>
        {/* Field Selector */}
        <FormFieldSelect
          control={control}
          data={fieldOptions}
          fieldName={`filters.${index}.field`}
          label="Select Field"
          required
          style={styles.dropdown}
        />

        {/* Operator Selector */}
        <AdvancedFilterOperatorField
          control={control}
          dropdownStyle={styles.dropdown}
          errors={errors}
          index={index}
          selectedField={selectedField}
          selectedOperator={selectedOperator}
          setValue={setValue}
          textViewStyle={styles.dropdown}
        />

        {/* Value Input */}
        <AdvancedFilterValueField
          control={control}
          dropdownStyle={styles.dropdown}
          errors={errors}
          index={index}
          selectedField={selectedField}
          selectedOperator={selectedOperator}
          setValue={setValue}
          textViewStyle={styles.dropdown}
        />
      </View>
      <IconButton
        icon="minus-circle"
        onPress={() => {
          remove(index)
        }}
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    dropdown: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },

    row: {
      alignItems: {
        xs: 'stretch',
        sm: 'center',
      },
      flexDirection: 'row',
      gap: theme.tokens.spacing[3],
      marginBottom: theme.tokens.spacing[3],
    },
    rowColumn: {
      flex: 1,
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      gap: theme.tokens.spacing[3],
    },
  }
})

export default AdvancedFilterRow
