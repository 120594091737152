import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterService from '../../../services/fosterService'
import FormFieldCheckbox from '../../form/FormFieldCheckbox'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import Skeleton from '../../Skeleton'
import { FosterForm } from '../fosterFormSchema'
import FosterThumbnailEdit from '../FosterThumbnailEdit'
import FormFieldGender from './formFields/FormFieldGender'
import FormFieldSpecies from './formFields/FormFieldSpecies'
import FormFieldVolunteer from './formFields/FormFieldVolunteer'
import fosterSharedStyles from './fosterSharedStyles'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
}

const FosterProfileCard = ({ control, formState }: Props) => {
  const { styles } = useStyles(stylesheet)
  const { styles: sharedStyles } = useStyles(fosterSharedStyles)

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, isLoading } = FosterService.useFoster(fosterId)

  return (
    <View style={styles.detailContainer}>
      <FosterThumbnailEdit fosterId={fosterId} />
      <Card title="Foster Details">
        <View style={sharedStyles.textInputContainer}>
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="name"
            isLoading={isLoading}
            label="Name"
            required={true}
            style={[styles.fosterDetailInput, styles.nameFlexBasis]}
          />
          <FormFieldSpecies
            control={control}
            errors={formState.errors}
            isLoading={isLoading}
            style={[styles.fosterDetailInput, styles.speciesFlexBasis]}
          />
          <FormFieldVolunteer
            control={control}
            formState={formState}
            isLoading={isLoading}
            style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
          />
          <FormFieldGender
            control={control}
            errors={formState.errors}
            isLoading={isLoading}
            style={[styles.fosterDetailInput, styles.speciesFlexBasis]}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="breed"
            isLoading={isLoading}
            label="Breed"
            required={false}
            style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="currentFood"
            isLoading={isLoading}
            label="Current Food"
            required={false}
            style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
          />
          <FormFieldDate
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="birthDate"
            isLoading={isLoading}
            label="Birth Date"
            required={false}
            style={styles.fosterDetailInput}
          />
          <FormFieldDate
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="intakeDate"
            isLoading={isLoading}
            label="Intake Date"
            required={false}
            style={styles.fosterDetailInput}
          />
          <View style={styles.fosterDetailInput}>
            <Skeleton isLoading={isLoading}>
              <FormFieldText
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="weight"
                isLoading={isLoading}
                keyboardType="numeric"
                label="Weight"
                required={false}
                style={styles.fosterDetailInput}
              />
              <Text style={styles.weightLabel}>lbs</Text>
              {foster?.weightDate && (
                <Text style={styles.weightDate} variant="labelSmall">
                  Last Updated:{' '}
                  {foster?.weightDate?.toLocaleDateString('en-US', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </Text>
              )}
            </Skeleton>
          </View>
          <FormFieldCheckbox
            control={control}
            disabled={isLoading}
            fieldName="isHospice"
            formState={formState}
            isLoading={isLoading}
            label="Hospice"
            required={false}
            status="checked"
          />
        </View>
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    detailContainer: {
      columnGap: theme.tokens.spacing[4],
      flexDirection: {
        sm: 'column',
        md: 'row',
      },
      rowGap: theme.tokens.spacing[4],
    },
    fosterDetailInput: {
      flexBasis: {
        xs: '40%',
        md: '30%',
      },
      flexGrow: 1,
      minWidth: 125,
    },
    fullWidthFlexBasis: {
      flexBasis: {
        xs: '100%',
        md: '30%',
      },
    },
    nameFlexBasis: {
      flexBasis: {
        xs: '60%',
        md: '35%',
      },
    },
    speciesFlexBasis: {
      flexBasis: {
        xs: '20%',
        md: '10%',
      },
    },
    weightDate: {
      color: theme.colors.subtitle,
      paddingLeft: theme.tokens.spacing[3],
      position: 'absolute',
      top: 60,
    },
    weightLabel: {
      color: theme.colors.subtitle,
      position: 'absolute',
      right: 10,
      top: 22,
    },
  }
})

export default FosterProfileCard
