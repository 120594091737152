import { MotiView, useAnimationState } from 'moti'
import { useEffect } from 'react'

// import { Easing } from 'react-native-reanimated' // TODO enable this when https://github.com/nandorojo/moti/issues/348 is fixed

const SlideTransition = ({
  children,
  active,
  direction,
  isPrimary,
  ...props
}: {
  children: React.ReactNode
  active: boolean
  direction: 'left' | 'right'
  isPrimary: boolean
} & React.ComponentProps<typeof MotiView>) => {
  let translateXActive = direction === 'left' ? -400 : 0
  let translateXInactive = direction === 'left' ? 0 : -400
  const opacityActive = direction === 'left' ? 0 : 1
  const opacityInactive = direction === 'left' ? 1 : 0

  if (!isPrimary) {
    translateXActive *= -1
    translateXInactive *= -1
  }

  const state = useAnimationState({
    active: {
      translateX: translateXActive,
      opacity: opacityActive,
      transition: {
        type: 'timing',
        duration: 250,
        // easing: Easing.ease, // TODO enable this when https://github.com/nandorojo/moti/issues/348 is fixed
      },
    },
    inactive: {
      translateX: translateXInactive,
      opacity: opacityInactive,
      transition: {
        type: 'timing',
        duration: 250,
        // easing: Easing.ease, // TODO enable this when https://github.com/nandorojo/moti/issues/348 is fixed
      },
    },
  })

  useEffect(() => {
    state.transitionTo(active ? 'active' : 'inactive')
  }, [active, state])

  return (
    <MotiView state={state} {...props}>
      {children}
    </MotiView>
  )
}

export default SlideTransition
