import { useEffect } from 'react'
import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  Path,
} from 'react-hook-form'
import { View } from 'react-native'
import { useSharedValue } from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FormFieldDate from '../../../form/FormFieldDate'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldOrganizationVaccines from '../../../organization/cards/formFields/FormFieldOrganizationVaccines'
import AccordionItem from './AccordionItem'

interface Props<C extends FieldValues, _E extends FieldErrors> {
  control: Control<C, any>
  formState: FormState<C>
  index: number
  open: boolean
  vaccination?: any
}

const FosterVaccinationItem = <C extends FieldValues, E extends FieldErrors>({
  control,
  formState,
  index,
  open,
  vaccination,
}: Props<C, E>) => {
  const { styles } = useStyles(stylesheet)

  const isExpanded = useSharedValue(open)

  useEffect(() => {
    isExpanded.value = open
  }, [isExpanded, open])

  return (
    <AccordionItem isExpanded={isExpanded} viewKey={`vaccination_${index}`}>
      <View style={styles.container}>
        <FormFieldOrganizationVaccines
          control={control}
          fieldName={`vaccinations.${index}.vaccineId` as Path<C>}
          formState={formState}
          label="Delay From Vaccine"
          style={styles.formField}
        />
        {!vaccination?.givenDate && (
          <FormFieldDate
            control={control}
            errors={formState.errors}
            fieldName={`vaccinations.${index}.dueDate` as Path<C>}
            label="Due On"
            required={false}
            style={styles.formField}
          />
        )}
        <FormFieldDate
          control={control}
          errors={formState.errors}
          fieldName={`vaccinations.${index}.givenDate` as Path<C>}
          label="Given On"
          required={false}
          style={styles.formField}
        />
        <FormFieldText
          control={control}
          errors={formState.errors}
          fieldName={`vaccinations.${index}.givenBy` as Path<C>}
          label="Clinic"
          required={true}
          style={styles.formField}
        />
      </View>
    </AccordionItem>
  )
}

export default FosterVaccinationItem

const stylesheet = createStyleSheet((theme) => {
  return {
    container: {
      gap: theme.tokens.spacing[2],
      width: '100%',
    },
    formField: {
      flex: 1,
    },
  }
})
