import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Card from './Card'

const NotFound = () => {
  const { styles } = useStyles(stylesheet)
  return (
    <Card style={styles.root} title="Not Found">
      <Text>The data you're looking for could not be found.</Text>
    </Card>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  root: {
    alignItems: 'center',
    alignSelf: 'center',
    paddingBottom: theme.tokens.spacing[6],
    paddingTop: theme.tokens.spacing[6],
    width: 500,
  },
}))

export default NotFound
