import { useCallback } from 'react'
import { Platform } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'
import log from '../../../utils/datadog/log/log'
import trpc from '../../../utils/trpc'
import Snackbar from '../../snackbar/Snackbar'

const usePrintPdfAction = (fosterId: string, currentOrganizationId: string) => {
  const pdfQuery = trpc.foster.pdf.useQuery(
    {
      id: fosterId,
      organizationId: currentOrganizationId,
    },
    {
      enabled: false,
    }
  )

  const prefetch = useCallback(() => {
    pdfQuery.refetch()
  }, [pdfQuery])

  const openPDF = useCallback(async () => {
    const data = await pdfQuery.refetch()

    const pdf = data.data

    if (pdfQuery.error) {
      log.error('There was a problem generating a PDF', pdfQuery.error)
      Snackbar.error('There was an error generating the PDF.')
      return
    }

    if (!pdf) {
      log.error('No PDF was returned')
      Snackbar.error('There was an error generating the PDF.')
      return
    }

    if (Platform.OS === 'web') {
      const base64ToBlob = (base64: string, mimeType: string) => {
        const byteCharacters = atob(base64)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        return new Blob([byteArray], { type: mimeType })
      }

      const pdfBlob = base64ToBlob(pdf, 'application/pdf')
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } else {
      const filePath = ReactNativeBlobUtil.fs.dirs.CacheDir + `/${fosterId}.pdf`
      const mimeType = 'application/pdf'

      await ReactNativeBlobUtil.fs.writeFile(filePath, pdf, 'base64')

      if (Platform.OS === 'android') {
        ReactNativeBlobUtil.android.actionViewIntent(filePath, mimeType)
      } else if (Platform.OS === 'ios') {
        ReactNativeBlobUtil.ios.openDocument(filePath)
      }
    }
  }, [fosterId, pdfQuery])

  return { openPDF, prefetch }
}

export default usePrintPdfAction
