import { Image, ImageStyle, useImage } from 'expo-image'
import { StyleProp } from 'react-native'
import Species from '../../enums/species'

interface Props {
  blurhash: string | null | undefined
  thumbUri: string | null | undefined
  species: Species
  style: StyleProp<ImageStyle>
  transition?: number
}

const FosterThumbnail = ({
  blurhash,
  thumbUri,
  species,
  style,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transition,
}: Props) => {
  const thumbnail = blurhash
    ? thumbUri
    : species === 'Cat'
      ? require('../../../assets/cat.png')
      : require('../../../assets/dog.png')

  const image = useImage(thumbnail, {
    maxWidth: 500,
    onError(error) {
      console.warn('Loading failed:', error.message, thumbUri)
    },
  })

  return (
    <Image
      accessibilityIgnoresInvertColors={true}
      cachePolicy={'disk'}
      contentFit="cover"
      placeholder={{ blurhash: blurhash || undefined }}
      source={image}
      style={style}
      testID="foster-image"
      // disabled for now due to a bug that blanks out images during a transition. will re-enable when fixed.
      // transition={transition || 0}
    />
  )
}

export default FosterThumbnail
