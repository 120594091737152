import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Snackbar as PaperSnackbar, Portal, useTheme } from 'react-native-paper'
import SnackbarManager from './Snackbar'

type State = {
  visible: boolean
  title?: string
  error?: boolean
}

const SnackbarComponent = () => {
  const theme = useTheme()
  const [state, setState] = useState<State>({ visible: false, error: false })

  useEffect(() => {
    SnackbarManager.setListener((title, error) =>
      setState({ visible: true, title, error })
    )
    return () => SnackbarManager.setListener(null)
  }, [])

  return (
    <Portal>
      <View
        style={{
          width: 400,
          flex: 1,
          position: 'absolute',
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <PaperSnackbar
          action={{
            label: 'Dismiss',
            textColor: state.error
              ? theme.colors.error
              : theme.colors.inversePrimary,
          }}
          duration={6000}
          onDismiss={() => setState({ ...state, visible: false })}
          testID="snackbar"
          visible={state.visible}
        >
          {state.title}
        </PaperSnackbar>
      </View>
    </Portal>
  )
}

export default SnackbarComponent
