import { zodResolver } from '@hookform/resolvers/zod'
import { router, useNavigation } from 'expo-router'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Divider, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import Species from '../../../../enums/species'
import useAutoSave from '../../../../hooks/useAutoSave'
import { useAppStore } from '../../../../store/useAppStore'
import trpc from '../../../../utils/trpc'
import FormFieldCheckbox from '../../../form/FormFieldCheckbox'
import FormFieldMultiSelect from '../../../form/FormFieldMultiSelect'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldSpecies from '../../../fosters/cards/formFields/FormFieldSpecies'
import Card from '../../../shared/Card'
import Page from '../../../shared/Page'

const vaccineFormSchema = z.object({
  autoSchedule: z.boolean().default(false),
  delayFromVaccineId: z.string().uuid().nullable().optional(),
  initialBoosterCount: z.coerce.number().int().nullable().optional(),
  initialBoosterCadenceMaxWeeks: z.coerce.number().int().nullable().optional(),
  initialBoosterCadenceMinWeeks: z.coerce.number().int().nullable().optional(),
  initialBoosterRecurringAgeWeeks: z.coerce
    .number()
    .int()
    .nullable()
    .optional(),
  longTermBoosterCadence: z.coerce.number().int().max(3).nullable().optional(),
  minAgeWeeks: z.coerce.number().int().positive(),
  name: z.string(),
  species: z.nativeEnum(Species),
  supersededByVaccineIds: z.array(z.string().uuid()).optional(),
})

type VaccineForm = z.infer<typeof vaccineFormSchema>

interface Props {
  vaccineId: string
}

const OrganizationEditVaccine = ({ vaccineId }: Props) => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()

  const isNewVaccine = vaccineId === 'new'

  const currentOrganizationId = useAppStore.use.currentOrganization().id

  const vaccineQuery = trpc.vaccine.byId.useQuery(
    { id: vaccineId },
    {
      enabled: !isNewVaccine,
    }
  )

  const vaccineListQuery = trpc.vaccine.list.useQuery(
    {
      organizationId: currentOrganizationId || '',
    },
    {
      enabled: currentOrganizationId !== '',
    }
  )

  const createVaccineMutation = trpc.vaccine.create.useMutation()
  const updateVaccineMutation = trpc.vaccine.update.useMutation()
  const deleteVaccineMutation = trpc.vaccine.delete.useMutation()

  const defaultValues = useMemo(() => {
    return {
      autoSchedule: vaccineQuery.data?.autoSchedule || false,
      delayFromVaccineId: vaccineQuery.data?.delayFromVaccineId || null,
      initialBoosterCount: vaccineQuery.data?.initialBoosterCount || null,
      initialBoosterCadenceMaxWeeks:
        vaccineQuery.data?.initialBoosterCadenceMaxWeeks || null,
      initialBoosterCadenceMinWeeks:
        vaccineQuery.data?.initialBoosterCadenceMinWeeks || null,
      initialBoosterRecurringAgeWeeks:
        vaccineQuery.data?.initialBoosterRecurringAgeWeeks || null,
      longTermBoosterCadence: vaccineQuery.data?.longTermBoosterCadence || null,
      minAgeWeeks: vaccineQuery.data?.minAgeWeeks || 0,
      name: vaccineQuery.data?.name || '',
      species: (vaccineQuery.data?.species || null) as Species,
      supersededByVaccineIds: vaccineQuery.data?.supersededByVaccineIds || [],
    }
  }, [vaccineQuery.data])

  const form = useForm<VaccineForm>({
    defaultValues,
    resolver: zodResolver(vaccineFormSchema),
  })

  const { control, formState, handleSubmit, reset } = form

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const onSave = (data: VaccineForm) => {
    if (
      isNewVaccine &&
      (createVaccineMutation.status === 'loading' ||
        createVaccineMutation.status === 'success')
    ) {
      return
    }

    const payload = {
      ...data,
      delayFromVaccineId: data.delayFromVaccineId || null,
      initialBoosterCount: data.initialBoosterCount || null,
      initialBoosterCadenceMaxWeeks: data.initialBoosterCadenceMaxWeeks || null,
      initialBoosterCadenceMinWeeks: data.initialBoosterCadenceMinWeeks || null,
      initialBoosterRecurringAgeWeeks:
        data.initialBoosterRecurringAgeWeeks || null,
      longTermBoosterCadence: data.longTermBoosterCadence || null,
      minAgeWeeks: data.minAgeWeeks,
      organizationId: currentOrganizationId || '',
      species: (data.species || null) as 'Dog' | 'Cat',
      supersededByVaccineIds: data.supersededByVaccineIds || [],
    }

    if (isNewVaccine) {
      createVaccineMutation.mutate(payload, {
        onSuccess: (newVaccine) => {
          router.replace(`/organization/vaccines/${newVaccine.id}`)
        },
      })
    } else {
      updateVaccineMutation.mutate({ id: vaccineId, ...payload })
    }
  }

  useEffect(() => {
    navigation.setOptions({
      title: isNewVaccine
        ? 'New Vaccine'
        : `${vaccineQuery.data?.name} Settings` || '',
    })
  })

  useAutoSave({
    form,
    defaultValues,
    onSave: handleSubmit(onSave),
  })

  if (!vaccineListQuery.isSuccess) {
    return null
  }

  const vaccineList =
    vaccineListQuery?.data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((vaccine) => ({
        label: vaccine.name,
        value: vaccine.id,
      })) || []

  return (
    <Page viewId="organization-new-vaccine">
      <Card title="Vaccine Info">
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Basic Info</Text>
          <View style={styles.vaccineRow}>
            <FormFieldText
              control={control}
              errors={formState.errors}
              fieldName="name"
              isLoading={!isNewVaccine && vaccineQuery.isLoading}
              label="Name"
              required={true}
              style={styles.flexContainer}
            />
            <FormFieldSpecies
              control={control}
              errors={formState.errors}
              isLoading={!isNewVaccine && vaccineQuery.isLoading}
              style={styles.flexContainer}
            />
          </View>
          <Text>
            How old should the animal be before they can receive this vaccine?
            This determines the initial due date for the vaccine when the
            scheduler adds it to the foster profile
          </Text>
          <FormFieldText
            control={control}
            errors={formState.errors}
            fieldName="minAgeWeeks"
            isLoading={!isNewVaccine && vaccineQuery.isLoading}
            keyboardType="numeric"
            label="Minimum Age in weeks"
            required={true}
          />
          <Text>
            Whether or not to include an initial vaccination on the generated
            schedule when a foster has no vaccination history
          </Text>
          <FormFieldCheckbox
            control={control}
            fieldName="autoSchedule"
            formState={formState}
            isLoading={!isNewVaccine && vaccineQuery.isLoading}
            label="Auto Schedule"
            required={false}
            status="checked"
          />
          <Divider />
        </View>
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Boosters</Text>
          <Text>How many weeks is the booster valid? Example: 3-4</Text>
          <View style={styles.vaccineRow}>
            <FormFieldText
              control={control}
              errors={formState.errors}
              fieldName="initialBoosterCadenceMinWeeks"
              isLoading={!isNewVaccine && vaccineQuery.isLoading}
              keyboardType="numeric"
              label="Min Weeks"
              required={true}
              style={styles.flexContainer}
            />
            <Text>-</Text>
            <FormFieldText
              control={control}
              errors={formState.errors}
              fieldName="initialBoosterCadenceMaxWeeks"
              isLoading={!isNewVaccine && vaccineQuery.isLoading}
              keyboardType="numeric"
              label="Max Weeks"
              required={true}
              style={styles.flexContainer}
            />
          </View>
          <Text>How many boosters are initially required?</Text>
          <FormFieldText
            control={control}
            errors={formState.errors}
            fieldName="initialBoosterCount"
            isLoading={!isNewVaccine && vaccineQuery.isLoading}
            keyboardType="numeric"
            label="Initial Booster count"
            required={true}
          />
          <Text>
            After the initial boosters are given if the vaccine has an ongoing
            cadence, how many years between each dose?
          </Text>
          <FormFieldText
            control={control}
            errors={formState.errors}
            fieldName="longTermBoosterCadence"
            isLoading={!isNewVaccine && vaccineQuery.isLoading}
            keyboardType="numeric"
            label="Yearly cadence"
            required={true}
          />
          <Text>
            In certain cases there's no firm number of initial boosters, such as
            when DHPP is given to puppies until they're 16 weeks of age
            regardless of how many boosters they've had already. In those cases
            specify the max age in weeks for the vaccine boosters in this field.
            Otherwise leave it blank.
          </Text>
          <Text>
            If the field is blank or the animal is older than the value set the
            booster count setting will be used instead when determining whether
            the next vaccine is a booster, or if the vaccine is transitioning to
            the long-term cadence
          </Text>
          <FormFieldText
            control={control}
            errors={formState.errors}
            fieldName="initialBoosterRecurringAgeWeeks"
            isLoading={!isNewVaccine && vaccineQuery.isLoading}
            keyboardType="numeric"
            label="Repeat Boosters until age"
            required={true}
          />
          <Divider />
        </View>
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Advanced</Text>
          <Text>
            When a common vaccine can be given in place of another vaccine, you
            can link them here. This is useful for allowing the scheduling
            system to handle cases such as DHPP/Lepto being given in place of
            DHLPP, or vice versa.
          </Text>
          <FormFieldMultiSelect
            control={control}
            data={vaccineList}
            errors={formState.errors}
            fieldName="supersededByVaccineIds"
            isLoading={!isNewVaccine && vaccineQuery.isLoading}
            label="Supersedes Vaccines"
            required={false}
            search={true}
            searchField="label"
            searchPlaceholder="Search for a vaccine"
          />
        </View>
        <Button
          mode="contained"
          onPress={() =>
            deleteVaccineMutation.mutate(
              {
                id: vaccineId,
              },
              {
                onSuccess: () => {
                  router.back()
                },
              }
            )
          }
        >
          Delete
        </Button>
      </Card>
    </Page>
  )
}

export default OrganizationEditVaccine

const stylesheet = createStyleSheet((theme) => {
  return {
    flexContainer: {
      flex: 1,
    },
    vaccineRow: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.tokens.spacing[4],
    },
    vaccineSection: {
      gap: theme.tokens.spacing[4],
      marginBottom: theme.tokens.spacing[4],
    },
  }
})
