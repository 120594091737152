import trpc from '../../../utils/trpc'
import AdoptionForm from '../adoptions/AdoptionForm'

interface Props {
  open: boolean
  onClose: () => void
}

const NewAdoptionAction = ({ open, onClose }: Props) => {
  const newAdoptionRecordMutation = trpc.foster.adoptions.create.useMutation()

  return (
    <AdoptionForm
      mutation={newAdoptionRecordMutation}
      onClose={onClose}
      open={open}
    />
  )
}

export default NewAdoptionAction
