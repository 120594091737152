import { Control, FormState, Path } from 'react-hook-form'
import { Text, View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { FosterBulkForm } from '../../../app/(app)/(tabs)/(fosters)/fosters/bulk'
import Species from '../../../enums/species'
import FormFieldCheckbox from '../../form/FormFieldCheckbox'
import FormFieldDate from '../../form/FormFieldDate'
import Card from '../../shared/Card'
import Skeleton from '../../Skeleton'
import { FosterForm } from '../fosterFormSchema'
import fosterSharedStyles from './fosterSharedStyles'

interface Props<T extends FosterForm | FosterBulkForm> {
  children?: React.ReactNode
  control: Control<T>
  formState: FormState<T>
  isLoading: boolean
  species?: Species
  submit?: () => void
}

const FosterMedicalForm = <T extends FosterForm | FosterBulkForm>({
  children,
  control,
  formState,
  isLoading,
  species,
  submit,
}: Props<T>) => {
  const { styles } = useStyles(fosterMedicalFormStylesheet)
  const { styles: sharedStyles } = useStyles(fosterSharedStyles)

  return (
    <Card title="Medical Info">
      <View style={sharedStyles.textInputContainer}>
        {!species && (
          <>
            {children && children}
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'positiveFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Positive Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'fecalFollowUpDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Fecal Follow-up Test Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'clearFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="✔️ Clear Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName={'fixedOnDate' as Path<T>}
                isLoading={isLoading}
                label="✂️ Spay/Neuter Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldCheckbox
                control={control}
                fieldName={'fixedBeforeIntake' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label="Fixed before intake"
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
          </>
        )}
        {species === Species.Dog && (
          <>
            {children && children}
            <Skeleton isLoading={isLoading}>
              <Text style={styles.medicalInfoText}>
                Puppies should be dewormed for roundworms at 2 weeks old, 4
                weeks old, 6 weeks old, and 8 weeks old. A fecal sample should
                also be dropped off at your veterinarian during their first
                vaccines to check for any other parasites. This deworming
                schedule also applies for the mom if you have them.
              </Text>
            </Skeleton>
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'positiveFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Positive Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'fecalFollowUpDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Fecal Follow-up Test Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'clearFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="✔️ Clear Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName={'heartwormTestingCompletedDate' as Path<T>}
                isLoading={isLoading}
                label="✔️ Heartworm Test Completed"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />

              <FormFieldCheckbox
                control={control}
                fieldName={'heartwormPositive' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label="Heartworm Positive"
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
            <Text style={styles.medicalInfoText}>
              Heartworm & flea/tick prevention should be given every 30 days
              year-round, starting at 9 weeks old. Spay/neuter surgery is
              usually done at 9 weeks and when the puppy is at least 2 lbs.
            </Text>
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'nextHeartwormPreventionDue' as Path<T>}
              isLoading={isLoading}
              label="💊 Next Heartworm Med Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'nextFleaTickMedDue' as Path<T>}
              isLoading={isLoading}
              label="💊 Next Flea/Tick Med Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName={'fixedOnDate' as Path<T>}
                isLoading={isLoading}
                label="✂️ Spay/Neuter Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />

              <FormFieldCheckbox
                control={control}
                fieldName={'fixedBeforeIntake' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label="Fixed before intake"
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
          </>
        )}
        {species === Species.Cat && (
          <>
            {children && children}
            <Text style={styles.medicalInfoText}>
              Kittens should be dewormed for roundworms at 2 weeks old, 4 weeks
              old, 6 weeks old, and 8 weeks old. A fecal sample should also be
              dropped off at your veterinarian during their first vaccines to
              check for any other parasites. This deworming schedule also
              applies for the mom if you have them.
            </Text>
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'positiveFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Positive Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'fecalFollowUpDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Fecal Follow-up Test Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'clearFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="✔️ Clear Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <Text style={styles.medicalInfoText}>
              Spay/neuter surgery is usually done around 11/12 weeks of age and
              when the kitten is at least 2 lbs.
            </Text>
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName={'comboTestingCompletedDate' as Path<T>}
                isLoading={isLoading}
                label="✔️ Combo Test Completed"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <View style={styles.medicalInfoCatComboCheckboxContainer}>
                <FormFieldCheckbox
                  control={control}
                  fieldName={'fivPositive' as Path<T>}
                  formState={formState}
                  isLoading={isLoading}
                  label="FIV Positive"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
                <FormFieldCheckbox
                  control={control}
                  fieldName={'felvPositive' as Path<T>}
                  formState={formState}
                  isLoading={isLoading}
                  label="FeLV Positive"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
              </View>
            </View>
            <FormFieldDate
              control={control}
              disabled={isLoading}
              errors={formState.errors}
              fieldName={'nextComboMedDue' as Path<T>}
              isLoading={isLoading}
              label="💊 Next Meds Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName={'fixedOnDate' as Path<T>}
                isLoading={isLoading}
                label="✂️ Spay/Neuter Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />

              <FormFieldCheckbox
                control={control}
                fieldName={'fixedBeforeIntake' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label="Fixed before intake"
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
          </>
        )}
      </View>
    </Card>
  )
}

export default FosterMedicalForm

export const fosterMedicalFormStylesheet = createStyleSheet((theme) => {
  return {
    medicalInfoCatComboCheckboxContainer: {
      flexDirection: 'row',
    },
    medicalInfoInput: {
      flexBasis: {
        xs: '50%',
        sm: '40%',
        lg: '20%',
      },
      flexGrow: 1,
      minWidth: 150,
    },
    medicalInfoText: {
      color: theme.colors.onSurface,
      flexWrap: 'wrap',
    },
  }
})
