import { useCallback } from 'react'
import FosterService from '../../../services/fosterService'
import trpc from '../../../utils/trpc'

const useMarkDeceased = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const fosterUpdateMutation = trpc.foster.update.useMutation()

  const { refreshFosterList } = FosterService.useFosterList()

  const markDeceased = useCallback(async () => {
    await fosterUpdateMutation.mutateAsync({
      id: fosterId,
      deceasedDate: new Date(),
      vaccinations: [],
    })

    await refreshFosterList()
  }, [fosterId, fosterUpdateMutation, refreshFosterList])

  return { markDeceased }
}

export default useMarkDeceased
