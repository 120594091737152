import { ReactNode, useCallback, useState } from 'react'
import { RefreshControl } from 'react-native-gesture-handler'
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { KeyboardOffset } from '../../../constants'

interface Props {
  children: ReactNode
  onRefresh?: () => Promise<void>
  viewId: string
}

const Page = ({ children, onRefresh, viewId }: Props) => {
  const { styles } = useStyles(stylesheet)
  const [refreshing, setRefreshing] = useState(false)

  const doRefresh = useCallback(() => {
    setRefreshing(true)
    onRefresh?.()
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false))
  }, [onRefresh])

  return (
    <KeyboardAwareScrollView
      bottomOffset={KeyboardOffset}
      contentContainerStyle={styles.container}
      contentInsetAdjustmentBehavior="automatic"
      nativeID={viewId}
      refreshControl={
        onRefresh ? (
          <RefreshControl onRefresh={doRefresh} refreshing={refreshing} />
        ) : undefined
      }
    >
      {children}
    </KeyboardAwareScrollView>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  container: {
    flexDirection: 'column',
    gap: theme.tokens.spacing[4],
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
}))

export default Page
