import { StateCreator } from 'zustand'

export interface AuthSlice {
  auth: {
    isLoggedIn: boolean | undefined
  }

  setAuth: (auth: { isLoggedIn: boolean | undefined }) => void
}

export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  auth: {
    isLoggedIn: undefined,
  },
  setAuth: ({ isLoggedIn }) => {
    set({ auth: { isLoggedIn } })
  },
})
