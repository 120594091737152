import { zodResolver } from '@hookform/resolvers/zod'
import { AppRouter } from '@rescuebase/api/src/router/app-router'
import type { inferRouterOutputs } from '@trpc/server'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { uuidv7 } from 'uuidv7'
import useAutoSave from '../../hooks/useAutoSave'
import FosterService from '../../services/fosterService'
import trpc from '../../utils/trpc'
import { prepareFormForSubmission } from '../form/formUtils'
import Snackbar from '../snackbar/Snackbar'
import fosterFormSchema, { FosterForm } from './fosterFormSchema'

type RouterOutput = inferRouterOutputs<AppRouter>

// We only include the explicit fields in our form, because the foster response is
// much more complicated and will trigger infinite re-renders in our autosave hook otherwise.
const fosterDefaults = (
  foster:
    | RouterOutput['foster']['byId']
    | RouterOutput['foster']['update']
    | undefined
) => {
  if (!foster) {
    return {}
  }

  return {
    birthDate: foster?.birthDate,
    breed: foster?.breed,
    deceasedDate: foster?.deceasedDate,
    clearFecalTestDate: foster?.clearFecalTestDate,
    comboTestingCompletedDate: foster?.comboTestingCompletedDate,
    currentFood: foster?.currentFood,
    fecalFollowUpDate: foster?.fecalFollowUpDate,
    felvPositive: foster?.felvPositive,
    fivPositive: foster?.fivPositive,
    fixedBeforeIntake: foster?.fixedBeforeIntake,
    fixedOnDate: foster?.fixedOnDate,
    gender: foster?.gender,
    heartwormPositive: foster?.heartwormPositive,
    heartwormTestingCompletedDate: foster?.heartwormTestingCompletedDate,
    history: foster?.history,
    intakeDate: foster?.intakeDate,
    isAdopted: foster?.isAdopted,
    isHospice: foster?.isHospice,
    microchipId: foster?.microchipId,
    name: foster?.name,
    nextComboMedDue: foster?.nextComboMedDue,
    nextFleaTickMedDue: foster?.nextFleaTickMedDue,
    nextHeartwormPreventionDue: foster?.nextHeartwormPreventionDue,
    notes: foster?.notes,
    positiveFecalTestDate: foster?.positiveFecalTestDate,
    privateNotes: foster?.privateNotes,
    readyForAdoption: foster?.readyForAdoption,
    species: foster?.species,
    training: foster?.training,
    userId: foster?.user?.id as string,
    vaccinations:
      foster?.vaccinations.map((v) => ({
        id: v.id || uuidv7(),
        vaccineId: v.vaccineId,
        fosterId: foster.id,
        dueDate: v.dueDate,
        givenDate: v.givenDate,
        givenBy: v.givenBy,
      })) || [],
    weight: foster?.weight?.toString(),
  }
}

const useFosterForm = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster } = FosterService.useFoster(fosterId)
  const { refreshFosterList } = FosterService.useFosterList()

  const fosterUpdateMutation = trpc.foster.update.useMutation()

  const [defaultValues, setDefaultValues] = useState(fosterDefaults(foster))

  const form = useForm<FosterForm>({
    mode: 'onBlur',
    resolver: zodResolver(fosterFormSchema),
    defaultValues: defaultValues,
  })

  const { formState, handleSubmit, reset, setValue, getValues } = form

  // This looks complex (and it is a bit), but it's necessary to do this manually with reset so that we don't
  // unmount and remount the vaccinations `useFieldArray` field, which causes the UI to have jank while users are editing vaccines
  useEffect(() => {
    Object.entries(defaultValues).forEach(([key, value]) => {
      if (key !== 'vaccinations') {
        if (!formState.dirtyFields[key as keyof FosterForm]) {
          setValue(key as keyof FosterForm, value, { shouldDirty: false })
        }
      } else if (key === 'vaccinations') {
        const vaccinations: FosterForm['vaccinations'] = (value ||
          []) as FosterForm['vaccinations']

        if (!vaccinations.length) {
          setValue('vaccinations', [], { shouldDirty: false })
        }

        const existingVaccinations = getValues().vaccinations

        if (
          existingVaccinations?.length === 0 &&
          !foster?.vaccinations?.length
        ) {
          return
        }

        // We _need_ to reset the form if the number of vaccines changes,
        // otherwise the `useFieldArray` vaccinations field seems to become uncontrolled
        if (
          existingVaccinations?.length < (foster?.vaccinations?.length || 0)
        ) {
          reset(defaultValues)
          return
        }

        for (let i = 0; i < existingVaccinations?.length; i++) {
          const vaccination = foster?.vaccinations.find(
            (v) => v.id === existingVaccinations[i].id
          )

          if (!vaccination) {
            continue
          }

          if (!formState.dirtyFields.vaccinations?.[i]?.id) {
            setValue(`vaccinations.${i}.id` as const, vaccination.id)
          }

          if (!formState.dirtyFields.vaccinations?.[i]?.fosterId) {
            setValue(`vaccinations.${i}.fosterId` as const, fosterId)
          }

          if (!formState.dirtyFields.vaccinations?.[i]?.vaccineId) {
            setValue(
              `vaccinations.${i}.vaccineId` as const,
              vaccination.vaccineId
            )
          }

          if (!formState.dirtyFields.vaccinations?.[i]?.dueDate) {
            setValue(
              `vaccinations.${i}.dueDate` as const,
              vaccination.dueDate || null
            )
          }

          if (!formState.dirtyFields.vaccinations?.[i]?.givenDate) {
            setValue(
              `vaccinations.${i}.givenDate` as const,
              vaccination.givenDate || null
            )
          }

          if (!formState.dirtyFields.vaccinations?.[i]?.givenBy) {
            setValue(
              `vaccinations.${i}.givenBy` as const,
              vaccination.givenBy || null
            )
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  useEffect(() => {
    if (foster) {
      setDefaultValues(fosterDefaults(foster))
    }
  }, [foster])

  const onSubmit = () => {
    handleSubmit((data) => {
      fosterUpdateMutation.mutate(
        {
          ...prepareFormForSubmission(data),
          id: fosterId,
          weight: data.weight ? parseFloat(data.weight) : null,
          vaccinations: [...(data.vaccinations || [])],
        },
        {
          onError: () => {
            Snackbar.error('There was a problem saving this foster')
          },
          onSuccess: async (updatedData) => {
            // It looks goofy setting default values here and then immediately queuing up a refresh, but this is necessary
            // to prevent the autosave hook from going into a loop while we wait for default values to update
            setDefaultValues(fosterDefaults(updatedData))
            await refreshFosterList()
            await refreshFoster()
          },
        }
      )
    })()
  }

  useAutoSave({
    form,
    defaultValues,
    onSave: onSubmit,
  })

  return form
}

export default useFosterForm
