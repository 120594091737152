import { View } from 'react-native'
import { Button, useTheme } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import UserService from '../../../services/userService'
import SaveStatus from '../../shared/SaveStatus'

const UserDetailHeaderContent = () => {
  const { styles } = useStyles(stylesheet)
  const theme = useTheme()
  const deactivateMutation = UserService.useDeactivateMutation()
  const { user, currentOrganization } = UserService.useUser()
  const isSaving = UserService.useIsSaving()

  const onDeactivate = () => {
    if (!user) return
    deactivateMutation.mutate({
      id: user.id,
      organizationId: currentOrganization?.id ?? '',
    })
  }

  return (
    <View style={styles.root}>
      {!user?.deactivatedAt && (
        <Button
          disabled={deactivateMutation.isLoading}
          loading={deactivateMutation.isLoading}
          onPress={onDeactivate}
          textColor={theme.colors.error}
        >
          Deactivate
        </Button>
      )}
      <SaveStatus
        isSaving={isSaving}
        updatedAt={user?.updatedAt}
        updatedBy={user?.updatedBy.firstName + ' ' + user?.updatedBy.lastName}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.tokens.spacing[2],
  },
}))

export default UserDetailHeaderContent
