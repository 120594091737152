// Shamelessly stolen from https://github.com/callstack/react-native-paper/issues/2825#issuecomment-1507560542

type Listener = (title: string, error: boolean) => void

class SnackbarManager {
  listener: Listener | null = null

  constructor() {
    this.error = this.error.bind(this)
    this.info = this.info.bind(this)
    this.setListener = this.setListener.bind(this)
  }

  setListener(listener: Listener | null): void {
    this.listener = listener
  }

  error(title: string): void {
    this.listener?.(title, true)
  }

  info(title: string): void {
    this.listener?.(title, false)
  }
}

export default new SnackbarManager()
