import { MotiSkeletonProps } from 'moti/build/skeleton/types'
import { Skeleton as MotiSkeleton } from 'moti/skeleton'
import { useTheme } from 'react-native-paper'

const Skeleton = ({ ...props }: Omit<MotiSkeletonProps, 'Gradient'>) => {
  const theme = useTheme()

  return <MotiSkeleton colorMode={theme.dark ? 'dark' : 'light'} {...props} />
}

Skeleton.Group = MotiSkeleton.Group

export default Skeleton
