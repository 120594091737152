import { StateCreator } from 'zustand'

export interface UsersSlice {
  includeDeactivated: boolean
  setIncludeDeactivated: (value: boolean) => void
}

export const createUsersSlice: StateCreator<UsersSlice> = (set) => ({
  includeDeactivated: false,
  setIncludeDeactivated: (value) => {
    set({ includeDeactivated: value })
  },
})
