import { DragDropContentView } from 'expo-drag-drop-content-view'
import * as ImagePicker from 'expo-image-picker'
import { memo, useState } from 'react'
import { Platform, Pressable, View } from 'react-native'
import { ActivityIndicator, Icon } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { ApiRoot } from '../../../constants'
import Species from '../../enums/species'
import FosterService from '../../services/fosterService'
import { useAppStore } from '../../store/useAppStore'
import prepareFileUpload from '../../utils/prepareFileUpload'
import Snackbar from '../snackbar/Snackbar'
import FosterThumbnail from './FosterThumbnail'

interface Props {
  fosterId: string
}

const FosterThumbnailEdit = ({ fosterId }: Props) => {
  const { styles, theme } = useStyles(stylesheet)

  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false)
  const [isFileDragging, setIsFileDragging] = useState(false)

  const currentOrganization = useAppStore.use.currentOrganization()

  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const { fosterList, refreshFosterList } = FosterService.useFosterList()

  const { blurhash, name, species, thumbUri } =
    foster || fosterList.find((f) => f.id === fosterId) || {}

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: false,
      allowsMultipleSelection: false,
      exif: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      selectionLimit: 1,
    })

    if (!result.canceled) {
      const file = result.assets[0]

      if (!file) {
        return
      }

      upload({
        fileName: file.fileName,
        size: file.fileSize || 0,
        type: file.mimeType,
        uri: file.uri,
      })
    }
  }

  const upload = async (file: {
    fileName?: string | null
    size: number
    type?: string
    uri: string
  }) => {
    setIsThumbnailUploading(true)

    let fileBlob: Blob | undefined
    if (Platform.OS === 'web') {
      const response = await fetch(file.uri)
      fileBlob = await response.blob()
    }

    const size = fileBlob?.size || file.size || 0

    if (size > 100 * 1024 * 1024) {
      Snackbar.error('File must be smaller than 100MB')
      setIsThumbnailUploading(false)
      return
    }

    const uploadResponse = await fetch(
      `${ApiRoot()}/${currentOrganization.id}/${fosterId}/thumb`,
      {
        method: 'POST',
        body: await prepareFileUpload({
          fields: {
            fosterId,
          },
          fileBlob,
          fileName: file.fileName || undefined,
          mimeType: file.type,
          uri: file.uri,
        }),
      }
    )

    if (!uploadResponse.ok) {
      if (uploadResponse.status === 413) {
        Snackbar.error('File must be smaller than 100MB')
      } else {
        Snackbar.error(`There was a problem saving the image for ${name}`)
      }

      return
    }

    await refreshFoster()

    setIsThumbnailUploading(false)

    refreshFosterList()
  }

  return (
    <DragDropContentView
      onDrop={(event) => {
        const file = event.assets[0]

        if (!file.uri) {
          return
        }

        upload({
          fileName: file.fileName,
          size: 0,
          type: file.type,
          uri: file.uri,
        })
      }}
      onEnter={() => setIsFileDragging(true)}
      onExit={() => setIsFileDragging(false)}
      style={styles.imagePressable}
    >
      <Pressable
        accessibilityRole="button"
        onPress={pickImage}
        style={styles.imagePressable}
        testID="foster-image-pressable"
      >
        {isFileDragging && <View style={styles.imageLoading} />}
        {isThumbnailUploading && (
          <ActivityIndicator size="large" style={styles.imageLoading} />
        )}
        <View style={styles.uploadIcon}>
          <Icon color={theme.colors.surface} size={30} source="upload" />
        </View>
        <FosterThumbnail
          blurhash={blurhash}
          species={species as Species}
          style={styles.image}
          thumbUri={thumbUri}
        />
      </Pressable>
    </DragDropContentView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    image: {
      borderRadius: theme.tokens.spacing[4],
      flex: 1,
    },
    imageLoading: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      borderRadius: theme.tokens.spacing[4],
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 20,
    },
    imagePressable: {
      alignSelf: 'center',
      height: 250,
      width: 250,
    },
    uploadIcon: {
      bottom: 0,
      padding: theme.tokens.spacing[3],
      position: 'absolute',
      right: 0,
      zIndex: 1,
    },
  }
})

export default memo(FosterThumbnailEdit)
