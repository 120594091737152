import { Text } from 'react-native-paper'
import Card from '../../shared/Card'

const OrganizationInfoCard = () => {
  return (
    <Card title="Info">
      <Text>Coming soon...</Text>
    </Card>
  )
}

export default OrganizationInfoCard
