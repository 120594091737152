import { getHeaderTitle } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { useNavigation } from 'expo-router'
import { Platform, View } from 'react-native'
import { Appbar, IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'

const FosterDetailHeader = ({
  route,
  title,
  options,
}: {
  route: RouteProp<Record<string, object | undefined>, string>
  title?: string
  options: NativeStackNavigationOptions
}) => {
  const { theme } = useStyles()
  const navigation = useNavigation()

  const showBackButton =
    navigation.canGoBack() &&
    !!(options as NativeStackNavigationOptions)?.headerBackVisible

  return (
    <Appbar.Header
      elevated={false}
      style={{
        backgroundColor: theme.colors.background,
        paddingLeft: showBackButton ? 0 : theme.tokens.spacing[4],
        paddingRight: headerXPadding,
      }}
    >
      {showBackButton && (
        <IconButton
          icon="arrow-left"
          iconColor={theme.colors.primary}
          onPress={() => {
            navigation.goBack()
          }}
          testID="back-icon-button"
        />
      )}

      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Appbar.Content
          style={{ flex: Platform.OS === 'web' ? 0.4 : 1 }}
          title={title || getHeaderTitle(options, route.name)}
        />
      </View>

      {options.headerRight && (
        <>{options.headerRight({ canGoBack: showBackButton })}</>
      )}
    </Appbar.Header>
  )
}

const headerXPadding = 16

export default FosterDetailHeader
