import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterService from '../../../services/fosterService'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import { FosterForm } from '../fosterFormSchema'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
}

const FosterNotesCard = ({ control, formState }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()
  const { isLoading } = FosterService.useFoster(fosterId)

  return (
    <View style={styles.historyAndNotesContainer}>
      <Card title="History & Training">
        <View
          style={{
            rowGap: 16,
          }}
        >
          <FormFieldText
            contentStyle={{ height: 150 }}
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="history"
            isLoading={isLoading}
            keyboardType="default"
            label="History"
            multiline={true}
            outlineStyle={styles.textFieldOutline}
            required={false}
            style={styles.textFieldContainer}
          />
          <Text style={styles.fieldLabel} variant="labelSmall">
            Ex. Where they came from, any background info
          </Text>
          <FormFieldText
            contentStyle={{ height: 150 }}
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="training"
            isLoading={isLoading}
            keyboardType="default"
            label="Training"
            multiline={true}
            outlineStyle={styles.textFieldOutline}
            required={false}
            style={styles.textFieldContainer}
          />
          <Text style={styles.fieldLabel} variant="labelSmall">
            Ex. House-trained, crate-trained, etc.
          </Text>
        </View>
      </Card>
      <Card title="Notes">
        <View
          style={{
            rowGap: 16,
          }}
        >
          <FormFieldText
            contentStyle={{ height: 185 }}
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="notes"
            isLoading={isLoading}
            keyboardType="default"
            label="Notes"
            multiline={true}
            outlineStyle={styles.textFieldOutline}
            required={false}
            style={{
              flexGrow: 1,
              height: 185,
              minWidth: 185,
            }}
          />
          <FormFieldText
            contentStyle={{ height: 150 }}
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="privateNotes"
            isLoading={isLoading}
            keyboardType="default"
            label="Private Notes"
            multiline={true}
            outlineStyle={styles.textFieldOutline}
            required={false}
            style={styles.textFieldContainer}
          />
          <Text style={styles.fieldLabel} variant="labelSmall">
            These notes will not be shown on the printout for the adopter
          </Text>
        </View>
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    fieldLabel: {
      color: theme.colors.subtitle,
      marginTop: -theme.tokens.spacing[3],
      paddingLeft: theme.tokens.spacing[2],
    },
    historyAndNotesContainer: {
      columnGap: theme.tokens.spacing[4],
      flexDirection: {
        sm: 'column',
        md: 'row',
      },
      rowGap: theme.tokens.spacing[4],
    },
    textFieldContainer: {
      flexGrow: 1,
      height: 150,
      minWidth: 150,
    },
    textFieldOutline: {
      height: '100%',
    },
  }
})

export default FosterNotesCard
