import FosterDocumentsCard from '../../../../../../components/fosters/cards/FosterDocumentsCard'
import FosterTab from '../../../../../../components/fosters/FosterTab'

const FosterDocumentsTab = () => {
  return (
    <FosterTab viewId="foster-documents-tab">
      <FosterDocumentsCard />
    </FosterTab>
  )
}

export default FosterDocumentsTab
