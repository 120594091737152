import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form'
import { DropdownProps } from 'react-native-element-dropdown/lib/typescript/components/Dropdown/model'
import Species from '../../../../enums/species'
import FormFieldSelect from '../../../form/FormFieldSelect'

type data = { label: string; value: string }

interface Props<C extends FieldValues, E extends FieldErrors> {
  control: Control<C, any>
  errors?: E
  fieldName?: Path<C>
  isLoading?: boolean
  submit?: () => void
}

const FormFieldSpecies = <C extends FieldValues, E extends FieldErrors>({
  control,
  errors,
  fieldName = 'species' as Path<C>,
  isLoading,
  submit,
  ...props
}: Props<C, E> & Partial<DropdownProps<data>>) => {
  return (
    <FormFieldSelect
      control={control}
      data={[
        { label: 'Dog', value: Species.Dog },
        { label: 'Cat', value: Species.Cat },
      ]}
      errors={errors}
      fieldName={fieldName}
      isLoading={isLoading}
      label="Species"
      onBlur={submit}
      required={false}
      style={props.style}
    />
  )
}

export default FormFieldSpecies
