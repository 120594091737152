import { View } from 'react-native'
import { Switch, Text } from 'react-native-paper'
import { useAppStore } from '../../../store/useAppStore'

const UserListHeaderContent = () => {
  const includeDeactivated = useAppStore.use.includeDeactivated()
  const setIncludeDeactivated = useAppStore.use.setIncludeDeactivated()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
      }}
    >
      <Text id="includeDeactivatedLabel">Include Deactivated</Text>
      <Switch
        aria-labelledby="includeDeactivatedLabel"
        onValueChange={() => setIncludeDeactivated(!includeDeactivated)}
        value={includeDeactivated}
      />
    </View>
  )
}

export default UserListHeaderContent
