import SuperTokens from 'supertokens-web-js'
import EmailPassword, { signIn } from 'supertokens-web-js/recipe/emailpassword'
import Passwordless, {
  consumeCode,
  createCode,
} from 'supertokens-web-js/recipe/passwordless'
import Session from 'supertokens-web-js/recipe/session'
import { ApiRoot } from '../../../constants'

SuperTokens.init({
  appInfo: {
    apiDomain: ApiRoot(),
    apiBasePath: '/api/auth',
    appName: 'Rescuebase (Local)',
  },
  recipeList: [
    Session.init({
      sessionTokenFrontendDomain: process.env.EXPO_PUBLIC_AUTH_ROOT_DOMAIN,
    }),
    EmailPassword.init(),
    Passwordless.init(),
  ],
})

export async function doesSessionExist() {
  return Session.doesSessionExist()
}

export async function getCode(email: string) {
  return createCode({
    email,
  })
}

export async function clearLoginAttemptInfo() {
  return Passwordless.clearLoginAttemptInfo()
}

export async function getLoginAttemptInfo() {
  return Passwordless.getLoginAttemptInfo()
}

export async function passwordSignIn(email: string, password: string) {
  return signIn({
    formFields: [
      {
        id: 'email',
        value: email,
      },
      {
        id: 'password',
        value: password,
      },
    ],
  })
}

export async function consumeLinkCode(
  _linkCode: string,
  _preAuthSessionId: string
) {
  return consumeCode()
}

export async function validateOtp(otp: string) {
  return consumeCode({
    userInputCode: otp,
  })
}
