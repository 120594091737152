import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { KeyboardAvoidingView, TextInput, View } from 'react-native'
import { Button, Modal, Portal } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import PaymentMethod from '../../../enums/paymentMethod'
import FosterService from '../../../services/fosterService'
import trpc from '../../../utils/trpc'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldSelect from '../../form/FormFieldSelect'
import FormFieldText from '../../form/FormFieldText'
import FormFieldVolunteer from '../cards/formFields/FormFieldVolunteer'

const adoptionSchema = z.object({
  adoptedByName: z.string(),
  adoptedByEmail: z.string().trim().toLowerCase().email(),
  adoptedName: z.string().nullable().optional(),
  adoptionDate: z.date(),
  paymentMethod: z.nativeEnum(PaymentMethod),
  returnDate: z.date().nullable().optional(),
  returnReason: z.string().nullable().optional(),
  userId: z.string().uuid(),
})

export type AdoptionRecordForm = z.infer<typeof adoptionSchema>

interface Props {
  adoption?: {
    id: string
    adoptedByName: string | null
    adoptedByEmail: string | null
    adoptedName: string | null
    adoptionDate: Date | null
    paymentMethod: PaymentMethod | null
    returnDate: Date | null
    returnReason: string | null
    userId: string
  }
  open: boolean
  onClose: () => void
  mutation:
    | ReturnType<typeof trpc.foster.adoptions.create.useMutation>
    | ReturnType<typeof trpc.foster.adoptions.update.useMutation>
}

const AdoptionForm = ({ adoption, open, onClose, mutation }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()

  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const defaultValues = useMemo(
    () => ({
      adoptedByName: adoption?.adoptedByName || '',
      adoptedByEmail: adoption?.adoptedByEmail || '',
      adoptedName: adoption?.adoptedName || null,
      adoptionDate: adoption?.adoptionDate || new Date(),
      userId: adoption?.userId || foster?.user.id,
      paymentMethod: adoption?.paymentMethod || PaymentMethod.Cash,
      returnDate: adoption?.returnDate || null,
      returnReason: adoption?.returnReason || '',
    }),
    [adoption, foster?.user.id]
  )

  const { control, formState, handleSubmit, reset } =
    useForm<AdoptionRecordForm>({
      mode: 'onBlur',
      resolver: zodResolver(adoptionSchema),
      defaultValues,
    })

  useEffect(() => {
    reset(
      {
        ...defaultValues,
      },
      {
        keepDirty: true,
      }
    )
  }, [defaultValues, reset, foster?.user.id])

  const submit = async () => {
    const currentlyFocusedInput = TextInput.State.currentlyFocusedInput()
    if (currentlyFocusedInput) {
      // This is a bit of a hack to force the event loop to loop around after processing the blur event,
      // thereby ensuring the UI has had a chance to update before we submit the form
      await new Promise<void>((resolve) => {
        TextInput.State.blurTextInput(currentlyFocusedInput)
        setTimeout(resolve, 100)
      })
    }

    await handleSubmit(async (data) => {
      mutation.mutateAsync(
        {
          fosterId,
          organizationId: foster?.organizationId || '',
          id: adoption?.id || '',
          data: {
            ...data,
          },
        },
        {
          onSuccess: () => {
            refreshFoster()
            reset()
            onClose()
          },
        }
      )
    })()
  }

  return (
    <>
      {open && (
        <Portal>
          <KeyboardAvoidingView
            behavior="height"
            enabled={open ? true : false}
            style={{
              height: open ? '100%' : 'auto',
            }}
          >
            <Modal
              contentContainerStyle={styles.root}
              onDismiss={onClose}
              visible={open}
            >
              <View style={styles.containerRow}>
                <FormFieldText
                  control={control}
                  errors={formState.errors}
                  fieldName="adoptedByName"
                  label="Adopted By"
                  required
                  style={styles.input}
                />
                <FormFieldText
                  control={control}
                  errors={formState.errors}
                  fieldName="adoptedByEmail"
                  label="Adopter's Email"
                  required
                  style={styles.input}
                />
              </View>
              <View style={styles.containerRow}>
                <FormFieldDate
                  control={control}
                  errors={formState.errors}
                  fieldName="adoptionDate"
                  label="Adoption Date"
                  required
                  style={styles.input}
                />
                <FormFieldSelect
                  control={control}
                  data={Object.values(PaymentMethod).map((value) => ({
                    label: value,
                    value,
                  }))}
                  errors={formState.errors as FieldErrors}
                  fieldName="paymentMethod"
                  label="Payment Method"
                  required
                  style={[{ marginTop: 3 }, styles.input]}
                />
                <FormFieldText
                  control={control}
                  errors={formState.errors}
                  fieldName="adoptedName"
                  label="Pet's Adopted Name (if different)"
                  required
                  style={styles.input}
                />
                <FormFieldVolunteer
                  control={control}
                  formState={formState}
                  label="Fostered By"
                  style={styles.input}
                />
              </View>
              {defaultValues?.returnDate && (
                <View style={styles.containerColumn}>
                  <FormFieldDate
                    control={control}
                    errors={formState.errors}
                    fieldName="returnDate"
                    label="Return Date"
                    required={false}
                  />
                  <FormFieldText
                    control={control}
                    errors={formState.errors}
                    fieldName="returnReason"
                    label="Reason for Return"
                    required={false}
                  />
                </View>
              )}
              <Button
                disabled={mutation.isLoading}
                loading={mutation.isLoading}
                mode="contained"
                onPress={submit}
              >
                {adoption ? 'Update' : 'Add Adoption'}
              </Button>
            </Modal>
          </KeyboardAvoidingView>
        </Portal>
      )}
    </>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    containerColumn: {
      flexDirection: 'column',
      gap: theme.tokens.spacing[3],
    },
    containerRow: {
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      gap: theme.tokens.spacing[3],
    },
    input: {
      flex: {
        md: 0.5,
      },
    },
    root: {
      alignSelf: 'center',
      backgroundColor: theme.colors.background,
      borderRadius: theme.tokens.containerBorderRadius,
      gap: theme.tokens.spacing[3],
      padding: theme.tokens.spacing[6],
      width: {
        xs: '95%',
        sm: '70%',
      },
    },
  }
})

export default AdoptionForm
