import { z } from 'zod'

const zodErrorMap: z.ZodErrorMap = (issue, _ctx) => {
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.expected === 'date') {
        return { message: 'Please enter a valid date' }
      }
      break
    case z.ZodIssueCode.too_small:
      if (issue.type === 'string') {
        return {
          message: `Please enter at least ${issue.minimum} character${issue.minimum > 1 ? 's' : ''}`,
        }
      }
      break
    default:
      return { message: _ctx.defaultError }
  }
  return { message: _ctx.defaultError }
}

export default zodErrorMap
