import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Switch, Text, TouchableRipple } from 'react-native-paper'
import { BasicFiltersSlice } from '../../store/basicFilterSlice'
import { useAppStore } from '../../store/useAppStore'

type BasicFilter = BasicFiltersSlice['basicFilters']['filters']

interface Props {
  filter: {
    [K in keyof BasicFilter]: BasicFilter[K] extends boolean ? K : never
  }[keyof BasicFilter]
  label: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

const GridFilterSimpleSwitch = ({ filter, label, style, textStyle }: Props) => {
  const basicFilters = useAppStore.use.basicFilters()
  const setBasicFilters = useAppStore.use.setBasicFilters()

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless
      onPress={() => {
        basicFilters.filters[filter] = !basicFilters.filters[filter]
        setBasicFilters({ ...basicFilters })
      }}
      style={style}
      testID={`${filter}-grid-filter-pressable`}
    >
      <>
        <Text style={textStyle}>{label}</Text>
        <Switch
          onValueChange={() => {
            basicFilters.filters[filter] = !basicFilters.filters[filter]
            setBasicFilters({ ...basicFilters })
          }}
          role="checkbox"
          testID={`${filter}-grid-filter-switch`}
          value={basicFilters.filters[filter]}
        />
      </>
    </TouchableRipple>
  )
}

export default GridFilterSimpleSwitch
