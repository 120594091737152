import { View } from 'react-native'
import { Text } from 'react-native-paper'
import Card from '../../shared/Card'

const OrganizationMedicalInfoCard = () => {
  return (
    <Card title="Medical Info">
      <View>
        <Text>Coming soon...</Text>
      </View>
    </Card>
  )
}

export default OrganizationMedicalInfoCard
