import { DrawerNavigationOptions } from '@react-navigation/drawer'
import { getHeaderTitle } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { router, useNavigation } from 'expo-router'
import { useState } from 'react'
import { Platform, View } from 'react-native'
import { Appbar, IconButton } from 'react-native-paper'
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../store/useAppStore'
import GridFilterSheet from '../filtering/GridFilterSheet'
import DesktopSearchBar from './DesktopSearchBar'

const UserListHeader = ({
  route,
  title,
  options,
}: {
  route: RouteProp<Record<string, object | undefined>, string>
  title?: string
  options: DrawerNavigationOptions | NativeStackNavigationOptions
}) => {
  const { theme } = useStyles()
  const navigation = useNavigation()
  const [filterSheetOpen, setFilterSheetOpen] = useState(false)
  const bulkSelect = useAppStore.use.bulkSelect()

  const showBackButton =
    navigation.canGoBack() &&
    !!(options as NativeStackNavigationOptions)?.headerBackVisible

  // Reanimated shared value for animation
  const animation = useSharedValue(0)

  // Update animation value based on selection state
  const isSelectionActive = bulkSelect.selection.items.length > 0
  animation.value = withTiming(isSelectionActive ? 1 : 0, {
    duration: 300,
  })

  // Animated style for header background color
  const animatedStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      animation.value,
      [0, 1],
      [theme.colors.background, theme.colors.secondaryContainer]
    )

    return { backgroundColor }
  })

  return (
    <Animated.View style={animatedStyle}>
      <Appbar.Header
        elevated={false}
        style={{
          paddingLeft: showBackButton ? 0 : theme.tokens.spacing[4],
          paddingRight: headerXPadding,
          backgroundColor: 'transparent', // Set to transparent to allow Animated.View to control background
        }}
      >
        <>
          {showBackButton && (
            <IconButton
              icon="arrow-left"
              iconColor={theme.colors.primary}
              onPress={() => {
                navigation.goBack()
              }}
              testID="back-icon-button"
            />
          )}

          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Appbar.Content
              style={{ flex: Platform.OS === 'web' ? 0.4 : 1 }}
              title={title || getHeaderTitle(options, route.name)}
            />

            {Platform.OS === 'web' && (
              <View
                style={{
                  alignItems: 'center',
                  bottom: -18,
                  left: '50%',
                  position: 'absolute',
                  transform: [{ translateX: '-50%' }],
                  width: '100%',
                }}
              >
                <DesktopSearchBar />
              </View>
            )}
          </View>

          {options.headerRight && (
            <>{options.headerRight({ canGoBack: showBackButton })}</>
          )}

          {Platform.OS !== 'web' && (
            <IconButton
              icon="magnify"
              onPress={() => {
                router.push(`fosters/search`)
              }}
              testID="search-icon-button"
            />
          )}

          <GridFilterSheet
            onClose={() => {
              setFilterSheetOpen(false)
            }}
            open={filterSheetOpen}
          />
        </>
      </Appbar.Header>
    </Animated.View>
  )
}

const headerXPadding = 16

export default UserListHeader
