import { zodResolver } from '@hookform/resolvers/zod'
import { router } from 'expo-router'
import { useForm } from 'react-hook-form'
import { Button, Modal, Portal } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import FosterService from '../../../services/fosterService'
import trpc from '../../../utils/trpc'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldText from '../../form/FormFieldText'

interface Props {
  open: boolean
  onClose: () => void
}

const newAdoptionRecordSchema = z.object({
  returnDate: z.date(),
  returnReason: z.string(),
})

type NewAdoptionRecordForm = z.infer<typeof newAdoptionRecordSchema>

const ReturnAdoptionAction = ({ open, onClose }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()

  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const adoptionReturnMutation = trpc.foster.adoptions.return.useMutation()

  const { control, formState, handleSubmit, reset } =
    useForm<NewAdoptionRecordForm>({
      mode: 'onBlur',
      resolver: zodResolver(newAdoptionRecordSchema),
      defaultValues: {
        returnDate: new Date(),
        returnReason: '',
      },
    })

  if (!foster) {
    return null
  }
  const onSubmit = handleSubmit(async (data) => {
    await adoptionReturnMutation.mutateAsync({
      data: {
        ...data,
      },
      fosterId: fosterId,
      organizationId: foster?.organizationId,
    })

    refreshFoster()

    router.push(`/fosters/${fosterId}/adoptions`)

    reset()
    onClose()
  })

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.root}
        onDismiss={onClose}
        visible={open}
      >
        <FormFieldDate
          blurOnSubmit
          control={control}
          errors={formState.errors}
          fieldName="returnDate"
          label="Return Date"
          required
        />
        <FormFieldText
          blurOnSubmit
          control={control}
          errors={formState.errors}
          fieldName="returnReason"
          label="Reason for Return"
          // placeholder="Enter foster name"
          required
        />
        <Button
          disabled={adoptionReturnMutation.isLoading}
          loading={adoptionReturnMutation.isLoading}
          mode="contained"
          onPress={onSubmit}
        >
          Mark Returned
        </Button>
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      backgroundColor: theme.colors.background,
      borderRadius: theme.tokens.containerBorderRadius,
      gap: 8,
      justifyContent: 'center',
      margin: 8,
      padding: theme.tokens.spacing[6],
    },
  }
})

export default ReturnAdoptionAction
