import FosterNotesCard from '../../../../../../components/fosters/cards/FosterNotesCard'
import FosterProfileCard from '../../../../../../components/fosters/cards/FosterProfileCard'
import { useFosterFormContext } from '../../../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../../../components/fosters/FosterTab'

const FosterInfoTab = () => {
  const { control, formState } = useFosterFormContext()

  return (
    <FosterTab viewId="foster-info-tab">
      <FosterProfileCard control={control} formState={formState} />
      <FosterNotesCard control={control} formState={formState} />
    </FosterTab>
  )
}

export default FosterInfoTab
